window.GekoppeldOpdracht = class GekoppeldOpdracht {
    items;
    selectorPrefix;
    elItems;
    varName;

    constructor(items, selectorPrefix, varName) {
        this.items = items;
        this.selectorPrefix = selectorPrefix;
        this.varName = varName;
        this.elItems = $(this.selectorPrefix + '-container');
        this.renderEl();
    }

    renderEl() {
        this.elItems.html('');
        this.items.forEach(item => {
            el = '<div class="gekoppeld-opdracht-item d-flex justify-content-between" id="' + item.uid + '">\n' +
                '<div class="bk-icon bk-icon--small sortable-handle flex-shrink-0 ui-sortable-handle"><i class="fal fa-grip-vertical"></i></div>\n' +
                '<div class="form-group flex-grow-1 ml-1 mr-1">\n' +
                '<input class="form-control" id="gekoppeld_opdracht_' + item.uid + '_titel"  type="text" value="' + item.titel + '" readonly>\n' +
                '</div>' +
                '<div class="bk-icon bk-icon--small flex-shrink-0" onclick="' + this.varName + '.deleteItem(\'' + item.uid + '\')"><i class="far fa-times"></i></div>\n' +
                '</div>';
            this.elItems.append(el);
        });
        this.updateFormInputItems();
    }

    getItem(uid) {
        //console.log('getItem', uid);
        let item = this.items.filter(i => i.uid === uid);
        //console.log('item', item);
        if (item.length) return item[0];
        return null;
    }

    addItem(titel = '', lesmateriaal_opdracht_id = 0, sort_order = 0) {
        console.log("addItem", titel, sort_order)
        console.log("addItem", this.items);
        let uid = window.uid();
        let item = {uid: uid, titel: titel.trim(), lesmateriaal_opdracht_id: lesmateriaal_opdracht_id, sort_order: sort_order};
        this.items.push(item);
        this.renderEl()
        $(this.selectorPrefix).val("");
    }

    deleteItem(uid) {
        //console.log("deleteItem", uid);
        this.items = this.items.filter(i => i.uid !== uid);
        this.renderEl();
    }

    reorderItems(uids) {
        console.log("reorderItems", uids);
        let item;
        let itemsNew = [];
        let teller = 0;
        uids.forEach((uid, index) => {
            item = this.getItem(uid);
            itemsNew.push({uid: item.uid, titel: item.titel, lesmateriaal_opdracht_id: item.lesmateriaal_opdracht_id, sort_order: index});
        });
        this.items = itemsNew;
        this.updateFormInputItems();
        //console.log('reorderItems', this.items);
    }

    updateFormInputItems() {
        //console.log('updateFormInputItems');
        $(this.selectorPrefix + '-items').val(JSON.stringify(this.items));
    }
}
