require('./general');
require('./layout');
require('./nav');
require('./vue');
require('./form');
require('./accordion');
require('./lesmateriaal');
require('./woord');
require('./combinatie');
require('./kruiswoordpuzzel');
require('./meerkeuze');
require('./woordzoeker');
require('./schema');
require('./volgorde');
require('./stelling');
require('./gekoppeld');
require('./gekoppeld-opdracht');
require('./woordenlijst');

