window.resize = function () {
    let bkAppModal1 = document.getElementById('bkAppModal1')
    if (bkAppModal1.style.right == '0px') {
        //open
    } else {
        //closed
        bkAppModal1.style.right = 0 - document.documentElement.clientWidth - 250
    }
    let bkAppModal2 = document.getElementById('bkAppModal2')
    if (bkAppModal2.style.right == '0px') {
        //open
    } else {
        //closed
        bkAppModal2.style.right = 0 - document.documentElement.clientWidth - (250 + 100)
    }
}

window.bkAppModalId = 0;

window.bkAppModalOpen = function (url) {
    //console.log('bkAppModalOpen');
    let bkAppModalId = window.bkAppModalId + 1;
    window.bkAppModalId = bkAppModalId;
    $("#bkAppModalOverlay" + bkAppModalId).addClass("bk-app__modal__overlay--show")
    $("#bkAppModal" + bkAppModalId).addClass("bk-app__modal--show")
    axios.get(url)
        .then(function (response) {
            $("#bkAppModal" + bkAppModalId).html(response.data);

        })
        .catch(function (error) {
            console.log(error);
        });
}

window.bkAppModalClose = function () {
    let bkAppModalId = window.bkAppModalId;
    window.bkAppModalId = bkAppModalId - 1;
    $("#bkAppModal" + bkAppModalId).removeClass("bk-app__modal--show")
    $("#bkAppModalOverlay" + bkAppModalId).removeClass("bk-app__modal__overlay--show")
    setTimeout(() => {
        $("#bkAppModal" + bkAppModalId).html('')
    }, 1000);
}

// window.rightPaneResizer = function (e) {
//     window.addEventListener('mousemove', mousemove);
//     window.addEventListener('mouseup', mouseup);
//
//     var prevX = e.x;
//     var rightPanel = rightPane.getBoundingClientRect();
//
//     function mousemove(e) {
//         let newX = prevX - e.x;
//         rightPane.style.width = rightPanel.width + newX + "px";
//     }
//
//     function mouseup() {
//         window.removeEventListener('mousemove', mousemove);
//         window.removeEventListener('mouseup', mouseup);
//     }
// }
//
// window.leftPaneResizer = function (e) {
//     window.addEventListener('mousemove', mousemove);
//     window.addEventListener('mouseup', mouseup);
//
//     var prevX = e.x;
//     var leftPanel = leftPane.getBoundingClientRect();
//
//     function mousemove(e) {
//         let newX = prevX - e.x;
//         leftPane.style.width = leftPanel.width - newX + "px";
//     }
//
//     function mouseup() {
//         window.removeEventListener('mousemove', mousemove);
//         window.removeEventListener('mouseup', mouseup);
//     }
// }

