window.schema = [];
window.schemaEventListener;

window.Schema = class Schema {
    uid = null;
    cellenEl = null;
    aantalX = 0;
    aantalY = 0;
    xTitels = 0;
    yTitels = 0;
    currentVoorbeeldAntwoorden = 1;
    cellen = [];
    celHoogte = '30px';
    celBreedte = '100px';

    constructor(uid, aantalX, aantalY, cellen, xTitels, yTitels) {
        this.uid = uid;
        this.aantalX = aantalX;
        this.aantalY = aantalY;
        this.cellen = cellen;
        this.cellenEl = $("#v-schema-" + uid + "-cellen");
        this.gWoordenVraagEl = $("#v-schema-" + uid + "-woorden-container");
        this.gWoordenPropertiesEl = $("#schema-" + uid + "-woorden-container");
        this.xTitels = xTitels;
        this.yTitels = yTitels;
        this.renderCellenEl();
    }

    setAantalX(x) {
        //console.log('setAantalX', x)
        this.aantalX = x;
        this.renderCellen();
        this.renderCellenEl();
    }

    setAantalY(y) {
        //console.log('setAantalY', y)
        this.aantalY = y;
        this.renderCellen();
        this.renderCellenEl();
    }

    toggleXTitels(checkboxEl) {
        //console.log('toggleXTitels', checkboxEl.checked);
        this.xTitels = checkboxEl.checked ? 1 : 0;
        this.renderCellen();
        this.renderCellenEl();
    }

    toggleYTitels(checkboxEl) {
        //console.log('toggleYTitels', checkboxEl.checked);
        this.yTitels = checkboxEl.checked ? 1 : 0;
        this.renderCellen();
        this.renderCellenEl();
    }

    toggleCurrentVoorbeeldAntwoorden(buttonEl) {
        //console.log('toggleCurrentVoorbeeldAntwoorden');
        this.currentVoorbeeldAntwoorden = this.currentVoorbeeldAntwoorden ? 0 : 1;
        let buttonText = this.currentVoorbeeldAntwoorden ? 'antwoorden' : 'voorbeeldantwoorden';
        buttonEl.html(buttonText);
        this.renderCellenEl();
    }

    getCel(cellen, x, y) {
        let cel = cellen.filter(c => c.x === x && c.y === y);
        if (cel.length) return cel[0];
        return null;
    }

    renderCellen() {
        //console.log('renderCellen');
        let currentCellen = this.cellen;
        let cellen = [];
        let antwoord, currentCel, titel, voorbeeldAntwoord;
        for (let y = 1; y <= this.aantalY; y++) {
            for (let x = 1; x <= this.aantalX; x++) {
                currentCel = this.getCel(currentCellen, x, y);
                titel = ((this.xTitels && y === 1) || (this.yTitels && x === 1)) ? 1 : 0;
                antwoord = currentCel ? currentCel.antwoord : '';
                voorbeeldAntwoord = currentCel ? currentCel.voorbeeld_antwoord : '';
                cellen.push({ x: x, y: y, titel: titel, antwoord: antwoord, voorbeeld_antwoord: voorbeeldAntwoord });
            }
        }
        this.cellen = cellen;
    }

    renderCellenEl() {
        //console.log('renderCellenEl', this.cellen, this.xTitels, this.yTitels);
        let cEl, classTitel, classText, text;
        this.cellenEl.html('');
        this.cellenEl.css('grid-template-columns', 'repeat(' + this.aantalX + ', ' + this.celBreedte + ')');
        this.cellenEl.css('grid-template-rows', 'repeat(' + this.aantalY + ', ' + this.celHoogte + ')');
        this.cellen.forEach(cel => {
            classTitel = cel.titel ? 'titel' : '';
            classText = cel.titel ? '' : this.currentVoorbeeldAntwoorden ? 'antwoorden' : 'voorbeeldantwoorden';
            text = cel.titel ? cel.antwoord : this.currentVoorbeeldAntwoorden ? cel.antwoord : cel.voorbeeld_antwoord;
            cEl = '<div class="v-schema__cel ' + classTitel + ' ' + classText + '" data-x="' + cel.x + '" data-y="' + cel.y + '">' +
                '<div class="v-schema__cel__debug">' + cel.x + ',' + cel.y + '</div>' +
                '<div class="v-schema__cel__content"><input value="' + text + '" type="text" onchange="schema[\'' + this.uid + '\'].updateCelText(' + cel.x + ', ' + cel.y + ', this.value)"/></div>' +
                '</div>';
            this.cellenEl.append(cEl);
        });
        this.updateFormInputCellen();
    }

    updateCelText(x, y, text) {
        this.cellen = this.cellen.map(c => {
            if (c.x === x && c.y === y) {
                let antwoord;
                let voorbeeldAntwoord;
                if (c.titel) {
                    antwoord = text;
                    voorbeeldAntwoord = c.voorbeeld_antwoord;
                } else {
                    antwoord = this.currentVoorbeeldAntwoorden ? text : c.antwoord;
                    voorbeeldAntwoord = this.currentVoorbeeldAntwoorden ? c.voorbeeld_antwoord : text;
                }
                return { x: c.x, y: c.y, titel: c.titel, antwoord: antwoord, voorbeeld_antwoord: voorbeeldAntwoord };
            }
            return c;
        });
        this.updateFormInputCellen();
        //console.log('this.cellen', this.cellen);
    }

    updateFormInputCellen() {
        //console.log('updateFormInputCellen', this.cellen);
        $('#schema-' + this.uid + '-cellen').val(JSON.stringify(this.cellen));
    }
}
