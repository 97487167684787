<template>
    <div class="toast-container">
        <notification-bar
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
            @removeNotification="removeNotification"
        />
    </div>
</template>

<script>
import NotificationBar from './NotificationBar'

export default {
    components: {
        NotificationBar
    },
    data() {
        return {
            nextId: 0,
            notifications: []
        }
    },
    methods: {
        addNotification(type, title, message) {
            let notification = {
                id: this.nextId++,
                type: type,
                title: title,
                message: message
            };
            this.notifications.push(notification);
        },
        removeNotification(id) {
            this.notifications = this.notifications.filter(n => n.id !== id);
        }
    }
}
</script>

<style>
.toast-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1090;
    width: 350px;
}
</style>
