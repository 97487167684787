$(document).on('change paste keyup', "#formOpdracht #titel", function() {
    //console.log("change paste keyup #formOpdracht #titel");
    $("#lesmateriaalTitel").html($(this).val());
});

$(document).on('paste', "[contenteditable]", function(e) {
    e.preventDefault();

    if (window.clipboardData) {
        let content = window.clipboardData.getData('text/html');
        content = cleanHTML(content);
        if (window.getSelection) {
            var selObj = window.getSelection();
            var selRange = selObj.getRangeAt(0);
            selRange.deleteContents();
            selRange.insertNode(document.createTextNode(content));
        }
    } else if (e.originalEvent.clipboardData) {
       let  content = (e.originalEvent || e).clipboardData.getData('text/html');
        content = cleanHTML(content);
        document.execCommand('insertHTML', false, content);
    }

});

$(document).on('click', ".vraag", function(e) {
    //console.log("click .vraag");
    setVraagSelected($(this).data('soort'), $(this).data('uid'));
//    e.stopPropagation();
});
$(document).on('click', ".panel__middle", function() {
    //$(".vraag").removeClass("vraag--selected");
});

$(document).on('click', "#verbergVraagnummer1", function() {
    updateVraagNummer();
});

$(document).on('click', "#pasteWoordenlijst", function() {
    pasteWoordenlijst();
});

window.pasteWoordenlijst = async function() {
    let woordRegels = []
    try {
        const items = await navigator.clipboard.read();
        for (const item of items) {
            var blob;

            if (item.types.indexOf('text/html') !== -1) {
                blob = await item.getType('text/html');
            } else {
                blob = await item.getType('text/plain');
            }
            const pHTML = await blob.text();

            $("#pasteContainer").html(cleanHTML(pHTML));

            let table = $("#pasteContainer table");

            if (table.length) {
                table.find('tr').each(function (i, el) {
                    var cells = $(this).find('td')
                    //console.log(cells)
                    if (cells.length == 2) {
                        let woord = cells.eq(0).html().replace(/(<br\s*\/?\s*>\s*)+$/, "").replace("'","’").trim();
                        let vertaling = cells.eq(1).html().replace(/(<br\s*\/?\s*>\s*)+$/, "").replace("'","’").trim();

                        woordRegels.push({woord: woord, vertaling: vertaling, zin: ''});
                    }
                    if (cells.length == 3) {
                        let woord = cells.eq(0).html().replace(/(<br\s*\/?\s*>\s*)+$/, "").replace("'","’").trim();
                        let vertaling = cells.eq(1).html().replace(/(<br\s*\/?\s*>\s*)+$/, "").replace("'","’").trim();
                        let zin = cells.eq(2).html().replace(/(<br\s*\/?\s*>\s*)+$/, "").replace("'","’").trim();

                        woordRegels.push({woord: woord, vertaling: vertaling, zin: zin});
                    }

                });
            }


        }

        for (let a = 0; a < woordRegels.length; a++) {
            woordenlijst.addItem(woordRegels[a].woord, woordRegels[a].vertaling, woordRegels[a].zin);
        }
        //console.log(woordRegels);
        $("#pasteContainer").html("");

        notify("success", 'Success',  'Woordenlijst toegevoegd.');
    } catch (err) {
        notify("error", 'Fout',  err.message);
        return [];
    }

}

$(document).on('click', "#pasteTitel", function() {
    parseTitel();
});

window.parseTitel = async function() {
    try {
        const items = await navigator.clipboard.read();
        for (const item of items) {
            var blob;

            if (item.types.indexOf('text/html') !== -1) {
                blob = await item.getType('text/html');
            } else {
                blob = await item.getType('text/plain');
            }
            const pHTML = await blob.text();

            let titel = pHTML.replace(/(<([^>]+)>)/gi, "").trim().replace(/(\r\n|\n|\r)/gm,"");
            let codering = titel.match(/\[([\S\s]*?)\]/gm);

            if (codering) {
                titel = titel.replace(codering[0], "").trim();

                let arrCodering = codering[0].replace("[", "").replace("]","").split("|");
                let bloom = arrCodering[0];
                let ERK = arrCodering[1];

                if (ERK) {
                    let arrERK = ERK.split("/");
                    if (arrERK) {
                        for (let a = 0; a < arrERK.length; a++) {
                            $("input[data-item='"+arrERK[a].trim()+"']").prop('checked',true)
                        }
                    }
                }

                let vraagIds = [];
                $('.vraag:not([data-soort=\'algemeen\']):not([data-soort=\'uitleg\'])').each(function( index ) {
                    vraagIds.push($(this).data("uid"));
                });

                if (bloom.indexOf(",") >= 0) {
                    let tempArr1 = bloom.trim().split(",");

                    for (let a = 0; a < tempArr1.length; a++) {
                        let tempArr2 = tempArr1[a].trim().split("=");

                        let BloomCodering = tempArr2[1].trim().split("");
                        let BloomOpdracht = tempArr2[0].trim().split("-");

                        if (BloomCodering.length > 1) {
                            for (var c = 0; c < BloomCodering.length; c++) {
                                if (BloomOpdracht.length > 1) {
                                    for (let d = BloomOpdracht[0]; d <= BloomOpdracht[1]; d++) {
                                        //console.log("a. Opdracht ", d, " BloomCodering = ", BloomCodering[c]);
                                        let uid = vraagIds[d-1];

                                        $("[name='bloom-" + uid+"[]'").each(function() {
                                            if ( $(this).data("item").substr(0,1) == BloomCodering[c]) {
                                                $(this).prop("checked", true);
                                            }
                                        });
                                    }
                                } else {
                                    //console.log("b. Opdracht ", BloomOpdracht[0], " BloomCodering = ", BloomCodering[c]);
                                    let uid = vraagIds[BloomOpdracht[0]-1];
                                    $("[name='bloom-" + uid+"[]'").each(function() {
                                        if ( $(this).data("item").substr(0,1) == BloomCodering[c]) {
                                            $(this).prop("checked", true);
                                        }
                                    });
                                }

                            }
                        } else {
                            if (BloomOpdracht.length > 1) {
                                for (let d = BloomOpdracht[0]; d <= BloomOpdracht[1]; d++) {
                                    //console.log("c. Opdracht ", d, " BloomCodering = ", BloomCodering[0]);

                                    let uid = vraagIds[d-1];
                                    $("[name='bloom-" + uid+"[]'").each(function() {
                                        if ( $(this).data("item").substr(0,1) == BloomCodering[0]) {
                                            $(this).prop("checked", true);
                                        }
                                    });
                                }
                            } else {
                                //console.log("d. Opdracht ", BloomOpdracht[0], " BloomCodering = ", BloomCodering[0]);

                                let uid = vraagIds[BloomOpdracht[0]-1];

                                $("[name='bloom-" + uid+"[]'").each(function() {
                                    if ( $(this).data("item").substr(0,1) == BloomCodering[0]) {
                                        $(this).prop("checked", true);
                                    }
                                });
                            }
                        }

                    }
                } else {
                    let BloomCodering = bloom.trim().split("");
                    for (let a = 0; a < BloomCodering.length; a++) {
                        //console.log("Alle opdrachten ", BloomCodering[a]);
                        for (let b = 0; b < vraagIds.length; b++) {
                            let uid = vraagIds[b];
                            $("[name='bloom-" + uid+"[]'").each(function() {
                                if ( $(this).data("item").substr(0,1) == BloomCodering[a]) {
                                    $(this).prop("checked", true);
                                }
                            });
                        }
                    }
                }
            }

            $("#titel").val(titel);
            $("#lesmateriaalTitel").html(titel);
        }

        notify("success", 'Success',  'Titel en niveau\'s toegevoegd.');
    } catch (err) {
        notify("error", 'Fout',  err.message);
        return [];
    }
}

$(document).on('click', "#draggable-sortable-opdracht .bk-list__item__icon--right .bk-icon", function() {
    //console.log(".bk-list__item__icon--right .bk-icon", $(this));
    let vraagSoort = $(this).closest(".bk-list__item").attr('data-soort');
    //let lesmateriaalId = $(this).closest(".lesmateriaal").attr("data-lesmateriaal_id");
    //let urlPrefix = $(this).closest(".lesmateriaal").attr("data-url-prefix");
    if (vraagSoort == "stelling") {
        setupStelling(vraagSoort, lesmateriaalId, urlPrefix);
    } else if (vraagSoort == "woord") {
        setupWoord(vraagSoort, lesmateriaalId, urlPrefix);
    } else if (vraagSoort == "combinatie") {
        setupCombinaties(vraagSoort, lesmateriaalId, urlPrefix);
    } else if (vraagSoort == "meerkeuze") {
        setupMeerkeuze(vraagSoort, lesmateriaalId, urlPrefix);
    } else {
        notify("error", 'Fout', "Vraag soort '" + vraagSoort+ "' niet geconfigureerd voor import.");
    }
});


window.setupStelling = async function(vraagSoort, lesmateriaalId, urlPrefix) {
    let stellingen = await parseStelling();

    if (stellingen.length) {
        for (let i = 0; i < stellingen.length; i++) {
            let s = stellingen[i];
            await createVraag(vraagSoort, s.uid, urlPrefix + '/' + lesmateriaalId);
            stelling[s.uid].setVelden(s.velden);
            stelling[s.uid].setContent(s.content);
            stelling[s.uid].setExtraVelden(s.extraVraag, s.extraAntwoord);

            stelling[s.uid].contentCheckVelden();
            stelling[s.uid].syncContent();
            stelling[s.uid].syncVelden();
        }

        let prefix = (stellingen.length > 1) ? ' stellingen' : ' stelling';
        notify("success", 'Success',  + stellingen.length + prefix + " toegevoegd.");
    } else {
        notify("error", 'Fout', "Geen stellingen gevonden in clipboard.");
    }
}

window.setupWoord = async function(vraagSoort, lesmateriaalId, urlPrefix) {
    let woordVragen = await parseWoord();

    if (woordVragen.length) {
        for (let i = 0; i < woordVragen.length; i++) {
            let s = woordVragen[i];
            await createVraag(vraagSoort, s.uid, urlPrefix + '/' + lesmateriaalId);

            woord[s.uid].setVelden(s.velden);
            woord[s.uid].setContent(s.content);

            woord[s.uid].contentCheckVelden();
            woord[s.uid].syncContent();
            woord[s.uid].syncVelden();
        }

        let prefix = (woordVragen.length > 1) ? ' vragen' : ' vraag';
        notify("success", 'Success',  + woordVragen.length + prefix + " toegevoegd.");
    } else {
        notify("error", 'Fout', "Geen Vul de ontbrekende woorden in gevonden in clipboard.");
    }
}

window.setupCombinaties = async function(vraagSoort, lesmateriaalId, urlPrefix) {
    let combinaties = await parseCombinaties();

    if (combinaties.length) {
        let vraagId = uid();
        await createVraag(vraagSoort, vraagId, urlPrefix + '/' + lesmateriaalId);
        for (let i = 0; i < combinaties.length; i++) {
            combinatie[vraagId].addItem(combinaties[i].vraag, combinaties[i].antwoord, (i+1));
        }

        notify("success", 'Success',  "Combinaties toegevoegd.");
    } else {
        notify("error", 'Fout', "Geen Combinaties in gevonden in clipboard.");
    }
}

window.setupMeerkeuze = async function(vraagSoort, lesmateriaalId, urlPrefix) {
    let meerkeuzes = await parseMeerkeuze();
    //console.log("meerkeuzes", meerkeuzes)

    if (meerkeuzes.length) {
        for (let i = 0; i < meerkeuzes.length; i++) {
            if (meerkeuzes[i].antwoorden.length) {
                let vraagId = meerkeuzes[i].uid;
                await createVraag(vraagSoort, vraagId, urlPrefix + '/' + lesmateriaalId);

                meerkeuzes[i].antwoorden.forEach((item) => {
                    createMeerkeuzeItemProperties(vraagId, item.item_uid, item.antwoord, item.correct);
                    createMeerkeuzeItemVraag(vraagId, item.item_uid, item.antwoord, item.correct);
                });
                toggleMeerkeuzeAbc(vraagId, true);
                $('#meerkeuze-'+vraagId+'-vraagstelling').val(meerkeuzes[i].vraag)
                $('#v-meerkeuze-'+vraagId+'-vraagstelling').html(meerkeuzes[i].vraag);
            }
        }

        notify("success", 'Success',  "Meerkeuze toegevoegd.");
    } else {
        notify("error", 'Fout', "Geen Meerkeuze in gevonden in clipboard.");
    }
}

window.loadVragen = async function(vragen) {
    /*
    for (var i = 0; i < vragen.length; i++) {
        var vraag = vragen[i];
        if (vraag != "") {
            vraag = vraag.split('-');

            var vraagSoort = vraag[1];
            var vraagUid = vraag[2];

            await createVraag(vraagSoort, vraagUid, urlPrefix + '/' + lesmateriaalId);
        }
    }*/
}

window.setVraagSelected = function(vraagSoort, vraagUid) {
    //console.log('setVraagSelected', vraagSoort, vraagUid);
    var vraagEl = $("#v-" + vraagSoort + '-' + vraagUid);
    var propertiesEl = $("#" + vraagSoort + '-' + vraagUid);
    // //unselect woord item
    // setWoordItemSelected();
    //select vraag middle panel
    $(".vraag").removeClass("vraag--selected");

    vraagEl.addClass("vraag--selected");
    //select properties vraag right panel
    $(".properties").each(function() {
        $(this).removeClass("properties--show");
    });
    propertiesEl.addClass("properties--show");
}


window.getHtml = async function(pURL) {
    return axios({
        method: "get",
        url: pURL,
    }).then(res => res.data);
}

window.createVraag = async function(vraagSoort, vraagUid, urlPrefix, replaceEl = null) {
    //console.log("createVraag", vraagUid);
    let getVraagHtml = await getHtml(urlPrefix + '/' + vraagSoort + '/' + vraagUid + '/vraag');
    let getPropertiesHtml = await getHtml(urlPrefix + '/' + vraagSoort + '/' + vraagUid + '/properties');

    $("#formOpdracht").append(getPropertiesHtml);

    if (replaceEl) {
        replaceEl.replaceWith(getVraagHtml);
    } else {
        $("#sortable-opdracht").append(getVraagHtml);
    }
    if (replaceEl) {
        setVraagSelected(vraagSoort, vraagUid);
    }
    $("#vragen").val(JSON.stringify($("#sortable-opdracht").sortable("toArray")));

    updateVraagNummer();
}

window.updateVraagNummer = function() {
    if($("#verbergVraagnummer1").is(':checked')) {
        $(".vraag__nummer").html("");
    } else {
        $('.vraag:not([data-soort=\'algemeen\']):not([data-soort=\'uitleg\'])').each(function( index ) {
            $(this).find('.vraag__nummer').html((index+1) + ". ");
        });
    }
}

$(document).on("click", ".vraag__actions [data-action]", function() {
    //console.log("click .vraag__actions [data-action]");
    var vraagEl = $(this).closest(".vraag");
    var vraagElId = vraagEl.attr("id");
    var vraagUid = vraagEl.data('uid');
    var propertiesEl = $('.properties[data-uid="' + vraagUid + '"]');
    var action = $(this).data("action");
    if (action == "delete") {
        //remove element from input vragen
        $("#vragen").val(JSON.stringify(JSON.parse($("#vragen").val()).filter(v => v !== vraagElId)));
        //add element to input vragen-remove
        var vragenDelete = JSON.parse($("#vragen-delete").val());
        vragenDelete.push(vraagElId);
        $("#vragen-delete").val(JSON.stringify(vragenDelete));
        //remove vraag from dom
        vraagEl.remove();
        //remove properties from dom
        propertiesEl.remove();
        updateVraagNummer();
    }
});

window.parseMeerkeuze = async function() {
    let meerkeuze = [];
    try {
        const items = await navigator.clipboard.read();
        for (const item of items) {
            var blob;

            if (item.types.indexOf('text/html') !== -1) {
                blob = await item.getType('text/html');
            } else {
                blob = await item.getType('text/plain');
            }
            const pHTML = await blob.text();

            var lineArray = pHTML.split('</p>');

            for(let a = 0; a < lineArray.length; a++) {

                let opdrachtString = cleanHTML(lineArray[a]);
                opdrachtString = opdrachtString.replaceAll('|TAB|','');

                let tempOpdrachtArray = opdrachtString.split('<br>');

                let vraagstelling = tempOpdrachtArray[0].replace(/\[([^)]+)\]/,'');
                let juisteAntwoord = '';

                let arrAntwoord = tempOpdrachtArray[0].match(/\[([^)]+)\]/);
                //console.log("arrAntwoord", arrAntwoord);
                if (arrAntwoord) {
                    if (arrAntwoord.length) {
                        juisteAntwoord = arrAntwoord[1];
                    }
                }

                let randomId = (Math.random() + 1).toString(36).substring(7);
                let antwoorden = [];

                for (let i = 1; i < tempOpdrachtArray.length; i++) {
                    if (tempOpdrachtArray[i] != "") {
                        let tempVraag = tempOpdrachtArray[i].split(" ");
                        let antwoordOptie = tempVraag[0];
                        let correct = 0;
                        if (antwoordOptie.trim() == juisteAntwoord.trim()) {
                            correct = 1;
                        }
                        antwoorden.push({item_uid:uid(), antwoord: tempOpdrachtArray[i].substr(tempOpdrachtArray[i].indexOf(" ") + 1), correct:correct});
                    }
                }

                meerkeuze.push({uid: uid(), vraag:vraagstelling, antwoorden: antwoorden});
            }
        }

        return meerkeuze;
    } catch (err) {
        notify("error", 'Fout',  err.message);
        return [];
    }
}

window.parseCombinaties = async function() {
    let combinaties = [];
    try {
        const items = await navigator.clipboard.read();
        for (const item of items) {
            var blob;

            if (item.types.indexOf('text/html') !== -1) {
                blob = await item.getType('text/html');
            } else {
                blob = await item.getType('text/plain');
            }
            const pHTML = await blob.text();

            var opdrachtString = cleanHTML(pHTML, false, false);

            var tempOpdrachtArray = opdrachtString.split('<br>');

            var vraagstelling = '';
            var vraagstellingAfgerond = false

            var vragen = [];
            var antwoorden = [];

            for (var i = 0; i < tempOpdrachtArray.length; i++) {
                var tempVraag = tempOpdrachtArray[i].trim().split(" ");

                if (tempVraag[0] != "1" && !vraagstellingAfgerond) {
                    vraagstelling += tempOpdrachtArray[i] + "<br>";
                    // eerste optie
                } else {
                    vraagstellingAfgerond = true;
                }

                if (vraagstellingAfgerond) {
                    if (isNaN(tempVraag[0])) {
                        tempVraag.shift();
                        antwoorden.push(tempVraag.join(' '));
                    } else {
                        tempVraag.shift();
                        vragen.push(tempVraag.join(' '));
                    }
                }
            }

            let arrLength = 0;
            if (vragen.length > antwoorden.length) {
                arrLength = vragen.length
            } else {
                arrLength = antwoorden.length
            }

            for (let a = 0; a < arrLength; a++) {
                let vraag = '';
                let antwoord = '';

                if (vragen[a] != undefined) { vraag = vragen[a]; }
                if (antwoorden[a] != undefined) { antwoord = antwoorden[a]; }
                if (vraag != '' || antwoord != '') {
                    combinaties.push({uid: uid(), vraag: vraag, antwoord: antwoord});
                }
            }
        }

        return combinaties;
    } catch (err) {
        notify("error", 'Fout',  err.message);
        return [];
    }
}

window.parseWoord = async function() {
    let woordVragen = [];
    try {
        const items = await navigator.clipboard.read();
        for (const item of items) {
            var blob;

            if (item.types.indexOf('text/html') !== -1) {
                blob = await item.getType('text/html');
            } else {
                blob = await item.getType('text/plain');
            }
            const pHTML = await blob.text();

            var lineArray = pHTML.split('</p>');

            for(var a = 0; a < lineArray.length; a++) {
                var opdrachtString = cleanHTML(lineArray[a]);

                var antwoordVelden = [];
                if (opdrachtString.length > 0) {

                    opdrachtString = opdrachtString.replace(/\@\s/gi, '@');
                    opdrachtString = opdrachtString.replace(/\.\s\[(.*?)\]/g, '[$1]. ');
                    opdrachtString = opdrachtString.replace(/\,\s\[(.*?)\]/g, '[$1], ');

                    opdrachtString = opdrachtString.replace(/\@2/gi, '@\n@');
                    opdrachtString = opdrachtString.replace(/\@3/gi, '@\n@\n@');
                    opdrachtString = opdrachtString.replace(/\@4/gi, '@\n@\n@\n@');
                    opdrachtString = opdrachtString.replace(/\@5/gi, '@\n@\n@\n@\n@');
                    opdrachtString = opdrachtString.replace(/\@6/gi, '@\n@\n@\n@\n@\n@');
                    opdrachtString = opdrachtString.replace(/\@7/gi, '@\n@\n@\n@\n@\n@\n@');
                    opdrachtString = opdrachtString.replace(/\@8/gi, '@\n@\n@\n@\n@\n@\n@\n@');
                    opdrachtString = opdrachtString.replace(/\@9/gi, '@\n@\n@\n@\n@\n@\n@\n@\n@');
                    opdrachtString = opdrachtString.replace(/\@10/gi, '@\n@\n@\n@\n@\n@\n@\n@\n@\n@');

                    var tempOpdrachtArray = opdrachtString.split(/(\@)/g);

                    var opdrachtArray = [];

                    var inOpdracht = false
                    for(var i = 0; i < tempOpdrachtArray.length; i++) {

                        tempOpdrachtArray[i] = tempOpdrachtArray[i].trim();

                        if (ignoreHTML(tempOpdrachtArray[i]) == "@" && inOpdracht == false) {
                            opdrachtArray.push({'type':'answerfield', 'nrLines': 1, 'display': 'inline', 'correct_answer': '' });
                            inOpdracht = true;
                        } else {

                            if (inOpdracht && ignoreHTML(tempOpdrachtArray[i]).length == 0) {
                                //newLine -> dus input = fullwidth
                                opdrachtArray[opdrachtArray.length-1]['display'] = 'fullwidth'
                                //console.log("aaa");
                            } else if (inOpdracht && ignoreHTML(tempOpdrachtArray[i]) == "@") {
                                opdrachtArray[opdrachtArray.length-1]['nrLines'] = opdrachtArray[opdrachtArray.length-1]['nrLines']+1
                                //console.log("bbb");
                            } else if (inOpdracht && tempOpdrachtArray[i].charAt(0) == "[") {
                                var tempTextArray = tempOpdrachtArray[i].split(']');
                                opdrachtArray[opdrachtArray.length-1]['correct_answer'] = tempTextArray[0].replace(/\[|\]/g, '');//.replace(/(<([^>]+)>)/gi, "");
                                //console.log("ccc");
                                if (tempTextArray[1].replace(/(<([^>]+)>)/gi, "").trim() != "") { //CHECK OF ZONDER HTML TAGS ER TEKST IN STAAT
                                    opdrachtArray.push({'type':'text', 'value': tempTextArray[1] });
                                }
                                inOpdracht = false;
                            } else {
                                //console.log("dddd");
                                inOpdracht = false;

                                if (tempOpdrachtArray[i].replace(/(<([^>]+)>)/gi, "").trim() != "") { //CHECK OF ZONDER HTML TAGS ER TEKST IN STAAT
                                    opdrachtArray.push({'type':'text', 'value': tempOpdrachtArray[i] });
                                }
                            }
                        }
                    }

                    for(var i = 0; i < opdrachtArray.length; i++) {
                        //CHECK OF VERKEERD IS INGEVOERD, ANTWOORD STAAT AAN EINDE VAN DE ZIN
                        //console.log(opdrachtArray[i]);
                        if (opdrachtArray[i]['type'] == "text") {
                            var antwoordInText = "";
                            var regExp = /\[([^)]+)\]/g;
                            var matches = opdrachtArray[i]['value'].match(regExp);
                            if (Array.isArray(matches)) {
                                antwoordInText = matches[0];
                                opdrachtArray[i-1]['correct_answer'] = antwoordInText.replace(/\[|\]/g, '').replace(/(<([^>]+)>)/gi, "");
                                opdrachtArray[i]['value'] = opdrachtArray[i]['value'].replace(matches[0], '');
                            }

                        }
                    }


                    let strHTML = '';
                    let randomId;
                    let type;
                    let vragen = [];
                    let vraagId = uid();

                    for (let i = 0; i < opdrachtArray.length; i++) {


                        if (opdrachtArray[i]['type'] == "text") {
                            strHTML += opdrachtArray[i]['value'];
                        }

                        if (opdrachtArray[i]['type'] == "answerfield") {
                            randomId = uid();
                            type = 3;

                            if (opdrachtArray[i]['correct_answer'].length < 7) {
                                type = 1;
                            }
                            if (opdrachtArray[i]['correct_answer'].length >= 7 && opdrachtArray[i]['correct_answer'].length < 15) {
                                type = 2;
                            }
                            if (opdrachtArray[i]['correct_answer'].length >= 15) {
                                type = 3;
                            }
                            if (opdrachtArray[i]['display'] == 'fullwidth' && opdrachtArray[i]['nrLines'] == 1) {
                                type = 3;
                            }

                            if (opdrachtArray[i]['display'] == 'fullwidth' && opdrachtArray[i]['nrLines'] > 1) {
                                type = 4;
                                strHTML += '<textarea class="v-woord__veld" id="v-woord-'+vraagId+'-veld-'+randomId+'" data-type="'+type+'" readonly="" data-uid="'+randomId+'" placeholder="'+opdrachtArray[i]['correct_answer']+'"></textarea>';
                            } else {
                                strHTML += ' <input type="text" class="v-woord__veld" id="v-woord-'+vraagId+'-veld-'+randomId+'" data-type="'+type+'" readonly="" data-uid="'+randomId+'" placeholder="'+opdrachtArray[i]['correct_answer']+'"> ';
                            }
                            vragen.push({uid:randomId,juiste_antwoord:opdrachtArray[i]['correct_answer'],placeholder:"",aantal_antwoordregels:opdrachtArray[i]['nrLines'], type:type,alleen_lezen:0});
                        }
                    }
                    woordVragen.push({uid: vraagId, content: strHTML, velden: vragen});
                }
            }
        }

        return woordVragen;
    } catch (err) {
        notify("error", 'Fout',  err.message);
        return [];
    }
}
window.parseStelling = async function() {
    let stellingen = [];
    let stellingObject, stellingContent, veldUid, stellingVeldItems;

    try {
        const items = await navigator.clipboard.read();
        for (const item of items) {
            var blob;

            if (item.types.indexOf('text/html') !== -1) {
                blob = await item.getType('text/html');
            } else {
                blob = await item.getType('text/plain');
            }
            const pHTML = await blob.text();

            var lineArray = pHTML.split('</p>');

            for(var a = 0; a < lineArray.length; a++) {

                var rechtsUitlijnen = 0;

                var opdrachtString = cleanHTML(lineArray[a], true);

                if (opdrachtString.length < 10) {
                    break;
                }
                //Check op TAB, anders vraag rechts uitlijnen
                if (opdrachtString.indexOf('|TAB|') >= 0) {
                    opdrachtString = opdrachtString.replaceAll('|TAB|', '');
                    rechtsUitlijnen = 1;
                }

                //Verwijder lege tags
                opdrachtString = opdrachtString.replace(/(<(?!\/)[^>]+>)+(<\/[^>]+>)+/g, "");
                opdrachtString = opdrachtString.replace('</b><b>', "");
                opdrachtString = opdrachtString.replace('</b> <b>', " ");

                //Zoek antwoord(en)
                let temp_antwoorden = opdrachtString.match(/\[(.*?)\]/g, opdrachtString);

                let antwoorden = [];
                //check als antwoord "," bevat -> anders meerdere antwoorden
                if (temp_antwoorden) {
                    for (var b = 0; b < temp_antwoorden.length; b++) {
                        opdrachtString = opdrachtString.replace(temp_antwoorden[b], "");
                        temp_antwoorden[b] = temp_antwoorden[b].replace("[", "").replace("]","");

                        var tempArr = temp_antwoorden[b].split(",");
                        for (var c = 0; c < tempArr.length; c++) {
                            antwoorden.push(tempArr[c].trim().replace(/<[^>]*>?/gm, ''));
                        }
                    }
                }

                //Zoek vragen
                let temp_vragen = opdrachtString.match(/<b>(.*?)<\/b>/gim, opdrachtString);
                let vragen = [];
                let stellingVeldItems = [];

                if (temp_vragen) {
                    var counter = 0;
                    for (var b = 0; b < temp_vragen.length; b++) {
                        stellingVeldItems = [];

                        var tempArr = temp_vragen[b].replace(/<\/?b>/g, "").split("/");

                        if (tempArr.length > 1) {
                            veldUid = uid();
                            var strStellingen = "";
                            for (var c = 0; c < tempArr.length; c++) {
                                let tempVraag = tempArr[c].trim().replace(/<[^>]*>?/gm, '');
                                var stellingId = uid();
                                stellingVeldItems.push({uid: stellingId, titel: tempVraag, correct: tempVraag == antwoorden[counter] ? 1 : 0})

                                if (strStellingen != "") {
                                    strStellingen += " / ";
                                }
                                if (tempVraag == antwoorden[counter] ) {
                                    tempVraag = '[b]' + tempVraag + '[/b]';
                                }

                                strStellingen += '<span id="v-'+stellingId+'">' + tempVraag + '</span>';
                            }

                            let strRight = "";
                            if (rechtsUitlijnen == 1) { strRight = "right"; }
                            opdrachtString = opdrachtString.replace(temp_vragen[b], '<div class="stelling '+strRight+'" contentEditable="false"  data-uid="' + veldUid + '">'+strStellingen+'</div>');
                            vragen.push({uid:veldUid, rechtsUitlijnen:rechtsUitlijnen, items:stellingVeldItems});
                            counter = counter + 1;
                        } else {
                            opdrachtString = opdrachtString.replace(temp_vragen[b], "");
                        }
                    }
                    opdrachtString = opdrachtString.replace(/<\/?b>/g, "").replace(/^\d+\s+/, '').replaceAll('[','<').replaceAll(']','>');
                }

                let extraVraag = 0;
                let extraAntwoord = '';
                if (opdrachtString.indexOf('@') >= 0) {
                    opdrachtString = opdrachtString.replaceAll('@', '');
                    extraVraag = 1;

                    if (antwoorden.length > 1) {
                        for (let a = vragen.length; a < antwoorden.length; a++) {
                            if (extraAntwoord != '') {
                                extraAntwoord += ', '
                            }
                            extraAntwoord += antwoorden[a];
                        }
                    }
                }

                stellingObject = {uid: uid(), content: opdrachtString, velden: vragen, extraVraag: extraVraag, extraAntwoord: extraAntwoord };
                stellingen.push(stellingObject);
            }
        }

        return stellingen;
    } catch (err) {
        // document.getElementById("demo").innerHTML = err.message;
        notify("error", 'Fout',  err.message);
        return [];
    }
}
window.ignoreHTML = function(input) {
    if (input.length > 0) {
        let output = input.replaceAll('<br>','');
        output = output.replaceAll(' ','');

        return output;
    } else {
        return input;
    }
}

window.cleanHTML = function(input, ignoreSpaces = false, removeLeadingNumber = true) {
    //add TABS
    var stringStripper = /<span.style=.mso-tab-count[\S\s]*?>/gim;
    var output = input.replace(stringStripper, '|TAB|');

    //remove line breaks / Mso classes
    stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")? ^p)/g;
    output = output.replace(stringStripper, ' ');

    stringStripper = /[\n\r]/g;
    output = output.replace(commentSripper, '');

    //strip Word generated HTML comments
    var commentSripper = new RegExp('<!--(.*?)-->', 'g');
    output = output.replace(commentSripper, '');

    //remove tags leave content if any
    var tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font|div|p)(.*?)>', 'gi');
    output = output.replace(tagStripper, '');

    //Remove everything in between and including tags '<style(.)style(.)>'
    var badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript'];

    for (var i = 0; i < badTags.length; i++) {
        tagStripper = new RegExp('<' + badTags[i] + '.*?' + badTags[i] + '(.*?)>', 'gi');
        output = output.replace(tagStripper, '');
        output = output.replace(/<\/p>/g, '<br />')
    }

    //remove attributes ' style="..."'
    var badAttributes = ['style', 'start'];
    for (var i = 0; i < badAttributes.length; i++) {
        var attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"', 'gi');
        output = output.replace(attributeStripper, '');
    }

    //remove multiple spaces
    output = output.replace(/\&nbsp;/gi, ' ')

    //remove multiple spaces
    output = output.replace(/  +/g, ' ');
    output = output.trim();

    if (removeLeadingNumber) {
        // remove leading number
        var outputArray = output.split(' ');

        if (!isNaN(outputArray[0])) {
            outputArray.shift();
            output = outputArray.join(' ');
        }
    }

    output = output.replace(/<(br)[^>]+>/ig, '<br>');
    output = output.replaceAll('<br />', '<br>');
    output = output.replaceAll('<br> ', '<br>');
    output = output.replaceAll(' <br>', '<br>');
    output = output.replaceAll('<br></b>', '</b><br>');
    output = output.replaceAll('<br></i>', '</i><br>');
    output = output.replaceAll('<br></u>', '</u><br>');
    output = output.replaceAll('<b></b>', '');

    return output;
}