window.stelling = [];
window.stellingOnchangeContentHasRegistered = [];

window.Stelling = class Stelling {
    uid = null;
    invulveld = null;
    invulveld_vraag = null;
    invulveld_antwoord_correct = null;
    velden = [];
    vContentEl = null;
    formContentEl = null;
    formVeldenEl = null;
    propertiesVeldenContainerEl = null;

    constructor(uid, velden, invulveld, invulveld_vraag, invulveld_antwoord_correct) {
        //console.log('constructor', velden);
        this.uid = uid;
        this.invulveld = invulveld;
        this.invulveld_vraag = invulveld_vraag;
        this.invulveld_antwoord_correct = invulveld_antwoord_correct;
        this.velden = velden;
        this.vContentEl = $('#v-stelling-' + this.uid + '-content');
        this.formContentEl = $('#stelling-' + this.uid + '-content');
        this.formVeldenEl = $('#stelling-' + this.uid + '-velden');
        this.propertiesVeldenContainerEl = $('#stelling-' + this.uid + '-veld-container');
        this.renderVeldenContainerEl();
        this.syncContent();
        this.syncVelden();
        this.registerOnchangeContent();
        this.registerVContentVeldEvents();
    }

    registerOnchangeContent() {
        if (window.stellingOnchangeContentHasRegistered.includes(this.uid)) {

        } else {
            window.stellingOnchangeContentHasRegistered.push(this.uid);
        }

        const contentSelector = '#v-stelling-' + this.uid + '-content';
        const $contentEl = $(contentSelector);

        if ($contentEl.data('inputEventRegistered')) {
            return;
        }

        $contentEl.on('input', () => {
            const $closestVraag = $contentEl.closest(".vraag");
            const uid = $closestVraag.attr('data-uid');
            window.stelling[uid].contentCheckVelden();
            window.stelling[uid].syncContent();
            window.stelling[uid].syncVelden();
        });

        $contentEl.data('inputEventRegistered', true);
    }

    registerVContentVeldEvents() {
        $(document).on('click', '#v-stelling-' + this.uid + '-content .stelling', function () {
            stelling[$(this).closest(".vraag").attr("data-uid")].onfocusVeld($(this).attr("data-uid"));
        });
    }

    setContent(content) {
        //console.log("setContent", content);
        this.vContentEl.html(content);
    }

    setVelden(velden) {
        //console.log("setVelden", velden);
        this.velden = velden;
    }

    setExtraVelden(extraVraag, extraAntwoord) {
        //console.log("setExtraVelden", extraVraag, extraAntwoord)

        this.invulveld = extraVraag;
        this.invulveld_vraag = '';
        this.invulveld_antwoord_correct = extraAntwoord;

        if (extraVraag) {
            $('#v-stelling-'+this.uid+'-vraag').removeClass('hide');
            $('#v-stelling-'+this.uid+'-vraag').find(':input').prop("placeholder", extraAntwoord)
            $('#stelling-'+this.uid+'-invulveld1').prop('checked', true );
        }
        $('#stelling-'+this.uid+'-invulveld_antwoord_correct').val(extraAntwoord);

    }

    syncContent() {
        //console.log("syncContent");
        this.formContentEl.val(this.vContentEl.html());
        //console.log(this.formContentEl.val());
    }

    syncVelden() {
        //console.log("syncVelden");
        //console.log('this.formVeldenEl', this.formVeldenEl);
        this.formVeldenEl.val(JSON.stringify(this.velden));
    }

    contentCheckVelden() {
        //console.log('contentCheckVelden()');
        //get all velden uids from this.vContentEl and rebuild this.velden
        let currentVelden = this.velden;
        let content = this.vContentEl.html();
        //console.log("content", content);
        let contentVeldenUids = this.extractStrings(content, 'data-uid="', '"');
        //console.log("contentVeldenUids", contentVeldenUids);
        let newVelden = [];
        let currentVeld;
        contentVeldenUids.forEach(uid => {
            currentVeld = currentVelden.filter(cv => cv.uid === uid);
            if (currentVeld.length) {
                newVelden.push(currentVeld[0]);
                return;
            }
            newVelden.push({uid: uid, rechtsUitlijnen: 0, items: [{uid: window.uid(), titel: '', correct: 0}]});
        });
        this.velden = newVelden;
        //console.log('newVelden', newVelden);
        this.renderVeldenContainerEl();
        this.syncVelden();
    }

    bold() {
        document.execCommand('bold', false);
    }

    italic() {
        document.execCommand('italic', false);
    }

    underline() {
        document.execCommand('underline', false);
    }

    renderVeldenContainerEl() {
        //console.log('renderVeldenContainerEl', this.velden);
        this.propertiesVeldenContainerEl.html('');
        this.velden.forEach(veld => {
            this.addVeldItemsEl(veld.uid, veld);
            veld.items.forEach(item => {
                this.addVeldItemEl(veld.uid, item);
            });
        });
    }

    addVeld() {
        //console.log("addVeld")
        let uid = window.uid();
        // this.velden.push({uid: uid, items: []});
        this.addVeldEl(uid);
    }

    addVeldEl(uid) {
        //console.log("addVeldEl")

        var Stelling1Id = window.uid();
        var Stelling2Id = window.uid();

        $("#v-stelling-" + this.uid + "-content").focus();
        pasteHtmlAtCursor('<div class="stelling" contentEditable="false" data-uid="' + uid + '"><span id="v-' + Stelling1Id + '">Stelling 1</span> / <span id="v-' + Stelling2Id + '">Stelling 2</span></div>');

        this.velden.push({
            uid: uid,
            rechtsUitlijnen: 0,
            items: [{uid: Stelling1Id, titel: 'Stelling 1', correct: 0}, {
                uid: Stelling2Id,
                titel: 'Stelling 2',
                correct: 0
            }]
        });

    }

    addVeldItemsEl(veldUid, veld) {
        //console.log("addVeldItemsEl", veld );

        let checked = veld.rechtsUitlijnen ? 'checked' : '';
        let elVeldItems = '' +
            '<div class="stelling__veld__items" id="stelling-' + this.uid + '-veld-' + veldUid + '-items">\n' +
            '    <div class="row">\n' +
            '       <div class="col-9">Stelling rechts uitlijnen ' +
            '        <input class="" type="checkbox" value="1" onclick="stelling[\'' + this.uid + '\'].updateVeldItemRechtsUitlijnen(\'' + veldUid + '\', this.checked)" ' + checked + '>\n' +
            '        </div>' +
            '        <div class="col-2">Correct</div>\n' +
            '        <div class="col-1 bk-icon bk-icon--small" onclick="stelling[\'' + this.uid + '\'].addVeldItem(\'' + veldUid + '\')"><i class="far fa-plus"></i></div>\n' +
            '    </div>\n' +
            '</div>';
        this.propertiesVeldenContainerEl.append(elVeldItems);
    }

    addVeldItem(veldUid) {
        //console.log("addVeldItem")
        let item = {uid: window.uid(), titel: '', correct: 0};
        this.velden = this.velden.map(v => {
            if (v.uid === veldUid) {
                let items = v.items;
                items.push(item);
                v.items = items;
                return v;
            }
            return v;
        });
        //this.syncVelden();
        this.addVeldItemEl(veldUid, item);
    }

    addVeldItemEl(veldUid, item) {
        //console.log("addVeldItemEl", this.velden);
        let checked = item.correct ? 'checked' : '';

        let elVeldItem = '' +
            '<div class="stelling-veld-item" id="stelling-' + this.uid + '-veld-' + veldUid + '-item-' + item.uid + '">\n' +
            '   <div class="row">' +
            '    <div class="col-9"><input class="form-control" type="text" value="' + item.titel + '" onkeyup="stelling[\'' + this.uid + '\'].onkeyupVeldItemTitel(\'' + item.uid + '\', this.value)" onfocus="stelling[\'' + this.uid + '\'].onfocusVeldItemTitel(\'' + veldUid + '\')" onblur="stelling[\'' + this.uid + '\'].onblurVeldItemTitel(\'' + veldUid + '\')" onchange="stelling[\'' + this.uid + '\'].updateVeldItemTitel(\'' + veldUid + '\',\'' + item.uid + '\', this.value)"></div>\n' +
            '    <div class="col-2 text-center">\n' +
            '        <input class="" type="checkbox" value="1" onclick="stelling[\'' + this.uid + '\'].updateVeldItemCorrect(\'' + veldUid + '\',\'' + item.uid + '\', this.checked)" ' + checked + '>\n' +
            '    </div>\n' +
            '    <div class="col-1 bk-icon bk-icon--small" onclick="stelling[\'' + this.uid + '\'].deleteVeldItem(\'' + veldUid + '\', \'' + item.uid + '\')"><i class="far fa-times"></i></div>\n' +
            '   </div>' +
            '</div>\n';
        $('#stelling-' + this.uid + '-veld-' + veldUid + '-items').append(elVeldItem);
    }

    updateVeldItemTitel(veldUid, uid, titel) {
        console.log('updateVeldItemTitel', uid, titel);
        $("#v-" + uid).html(titel);

        this.velden = this.velden.map(v => {
            if (v.uid === veldUid) {
                v.items = v.items.map(i => {
                    if (i.uid === uid) {
                        return {uid: i.uid, titel: titel, correct: i.correct};
                    }
                    return i;
                });
            }
            return v;
        });

        this.parseVeldenLeft();
        this.syncVelden();
    }

    updateVeldItemCorrect(veldUid, uid, correct) {
        //console.log('updateVeldItemCorrect', uid, correct);
        this.velden = this.velden.map(v => {
            if (v.uid === veldUid) {
                v.items = v.items.map(i => {
                    if (i.uid === uid) {
                        return {uid: i.uid, titel: i.titel, correct: correct ? 1 : 0};
                    }
                    return i;
                });
            }
            return v;
        });
        this.parseVeldenLeft()
        this.syncVelden();
    }

    updateVeldItemRechtsUitlijnen(veldUid, rechtsUitlijnen) {
        //console.log('updateVeldItemCorrectupdateVeldItemRechtsUitlijnen', veldUid, rechtsUitlijnen);
        if (rechtsUitlijnen == 1) {
            $('[data-uid="' + veldUid + '"]').addClass("right");
        } else {
            $('[data-uid="' + veldUid + '"]').removeClass("right");
        }
        this.velden = this.velden.map(v => {
            if (v.uid === veldUid) {
                return {uid: v.uid, id: v.id, rechtsUitlijnen: rechtsUitlijnen ? 1 : 0, items: v.items}
            }
            return v;
        });
        this.syncVelden();
        this.syncContent();
    }

    deleteVeldItem(veldUid, uid) {
        //console.log('deleteVeldItem', veldUid, uid);
        this.velden = this.velden.map(v => {
            if (v.uid === veldUid) {
                v.items = v.items.filter(i => i.uid !== uid);
            }
            return v;
        });
        this.parseVeldenLeft();
        this.syncVelden();
    }

    onfocusVeld(uid) {
        //console.log('onfocusVeld', uid);
        this.vContentEl.find('[data-uid="' + uid + '"]').addClass("focus");
        $("#stelling-" + this.uid + "-veld-" + uid + "-items").addClass("focus");
        $("#stelling-" + this.uid + "-veld-" + uid + "-items").addClass("focus").find("input").first().focus();
        this.syncContent();
    }

    onblurVeld(uid) {
        // //console.log('onfocusVeld', uid);
        this.vContentEl.find('[data-uid="' + uid + '"]').removeClass("focus");
        $("#stelling-" + this.uid + "-veld-" + uid + "-items").removeClass("focus");
        this.syncContent();
    }

    onfocusVeldItemTitel(uid) {
        $('.stelling').removeClass("focus");
        this.vContentEl.find('[data-uid="' + uid + '"]').addClass("focus");
        this.syncContent();
    }

    onblurVeldItemTitel(uid) {
        //console.log('onblurVeldItemTitel');
        this.vContentEl.find('[data-uid="' + uid + '"]').removeClass("focus");
        this.syncContent();
    }

    onkeyupVeldItemTitel(uid, titel) {
        //console.log('onkeyupVeldItemTitel', uid, titel);
        $("#v-" + uid).find(".v-volgorde__item__left").html(titel);
        this.syncContent();
    }

    deactivateVelden() {
        $(".stelling-veld").removeClass("active");
    }

    parseVeldenLeft() {
        for (let a = 0; a < this.velden.length; a++) {
            let veld = this.velden[a];
            let veldItems = veld.items;

            let strStellingen = "";
            for (let b = 0; b < veldItems.length; b++) {
                let veldItem = veldItems[b];
                if (strStellingen != "") {
                    strStellingen += " / ";
                }
                console.log("veldItem", veldItem);
                let strAntwoord = veldItem.titel;
                if (veldItem.correct) {
                    strAntwoord = '<b>' + strAntwoord + '</b>';
                }
                strStellingen += '<span id="v-' + veldItem.uid + '">' + strAntwoord + '</span>';
            }
            this.vContentEl.find('[data-uid="' + veld.uid + '"]').html(strStellingen);
        }
        //console.log("parseVeldenLeft")
    }

    extractStrings(str, start, end) {
        let t = 0;
        let endIndex = 0;
        let startIndex;
        let words = [];
        while (true) {
            t++;
            if (t > 100) {
                //console.log('break nok');
                break;
            }
            startIndex = str.indexOf(start, endIndex);
            if (startIndex == -1) {
                break;
            }
            startIndex = startIndex + start.length;
            endIndex = str.indexOf(end, startIndex);
            if (endIndex == -1) {
                break;
            }
            if (endIndex > startIndex) {
                words.push(str.substring(startIndex, endIndex));
            } else {
                break;
            }
        }
        return words;
    }
}
