window.createMeerkeuzeItemProperties = function (meerkeuzeUid, itemUid, antwoord = '', correct = 0) {
    //console.log('createMeerkeuzeItemProperties', meerkeuzeUid, itemUid);
    let isNew = false;

    if (!itemUid) {
        itemUid = uid();
        isNew = true;
    }

    let strChecked = '';
    if (correct == 1) {
        strChecked = 'checked';
    }
    let elVraagProperties = '<div class="meerkeuze-item d-flex justify-content-between" id="meerkeuze-'+meerkeuzeUid+'-item-'+itemUid+'" data-uid="'+itemUid+'">\n' +
        '        <div class="form-group mb-0 flex-grow-1">\n' +
        '    <input id="meerkeuze-'+meerkeuzeUid+'-item-'+itemUid+'-antwoord" class="form-control" value="'+antwoord+'"  name="meerkeuze-'+meerkeuzeUid+'-item-'+itemUid+'-antwoord" type="text">\n' +
        '    <div class="validator-message"></div>\n' +
        '</div>\n' +
        '        <div class="form-check form-check-inline">\n' +
        '    <input id="meerkeuze-'+meerkeuzeUid+'-item-'+itemUid+'-correct1" class="form-check-input" role="button" onclick="toggleCorrecteAntwoord(\'' + meerkeuzeUid + '\',\'' + itemUid + '\')"  name="meerkeuze-'+meerkeuzeUid+'-item-'+itemUid+'-correct" '+strChecked+' type="checkbox" value="1">\n' +
        '    <label class="form-check-label" for="meerkeuze-'+meerkeuzeUid+'-item-'+itemUid+'-correct1" role="button"></label>\n' +
        '</div>\n' +
        '        <div class="bk-icon bk-icon--small flex-shrink-0" onclick="deleteMeerkeuzeItemProperties(\'' + meerkeuzeUid + '\',\'' + itemUid + '\')"><i class="far fa-times"></i></div>\n' +
        '    </div>';

    $("#meerkeuze-" + meerkeuzeUid + "-items-container").append(elVraagProperties);

    if (isNew) {
        createMeerkeuzeItemVraag(meerkeuzeUid, itemUid, '');
    }
}

window.toggleCorrecteAntwoord = function(meerkeuzeUid, itemUid) {
    let prefix = 'meerkeuze-'+meerkeuzeUid+'-item-'+itemUid;
    let checkboxEl = $('#'+prefix+'-correct1');
    if (checkboxEl.is(":checked")) {
        $('#v-'+prefix+'-correct').html("<b>*</b>");
    } else {
        $('#v-'+prefix+'-correct').html("");
    }
}

window.createMeerkeuzeItemVraag = function (meerkeuzeUid, itemUid, pAntwoord = '', correct = false) {
    //console.log('createMeerkeuzeItemVraag', meerkeuzeUid, itemUid, pAntwoord, correct);

    let strChecked = '';
    if (correct === 1) {
        strChecked = '*';
    }

    var prefix = 'meerkeuze-'+meerkeuzeUid+'-item-'+itemUid;
    let antwoord = pAntwoord;
    var elVraag = '<div class="v-meerkeuze-item d-flex mb-1">\n' +
        '    <div class="v-meerkeuze-item__abc"></div>\n' +
        '    <div class="v-meerkeuze-item__checkbox"></div>\n' +
        '    <div class="v-meerkeuze-item__antwoord" id="v-'+prefix+'-antwoord">'+antwoord+'</div>\n' +
        '    <div class="v-meerkeuze-item__correct" id="v-'+prefix+'-correct"><b>'+strChecked+'</b></div>\n' +
        '</div>';

    $(elVraag).insertBefore("#v-meerkeuze-" + meerkeuzeUid + "-invulveld");
    syncInput(prefix+'-antwoord', 'v-'+prefix+'-antwoord');
    syncInputFocus(prefix+'-antwoord', 'v-'+prefix+'-antwoord', 'v-meerkeuze-item');

    var meerkeuzeItems = [];
    $("#meerkeuze-" + meerkeuzeUid + "-items-container .meerkeuze-item").each(function () {
        meerkeuzeItems.push($(this).data('uid').toString());
    });
    $("input#meerkeuze-" + meerkeuzeUid + "-items").val(JSON.stringify(meerkeuzeItems));

}

window.deleteMeerkeuzeItemVraag = function (meerkeuzeUid, itemUid) {
    $("#v-meerkeuze-" + meerkeuzeUid + "-item-" + itemUid+ "-antwoord").parent().remove();
    $("#v-meerkeuze-" + meerkeuzeUid + "-item-" + itemUid + "-antwoord").remove();
}

window.deleteMeerkeuzeItemProperties = function (meerkeuzeUid, itemUid) {
    $("#meerkeuze-" + meerkeuzeUid + "-item-" + itemUid).remove();
    var meerkeuzeItems = JSON.parse($("input#meerkeuze-" + meerkeuzeUid + "-items").val());
    meerkeuzeItems = meerkeuzeItems.filter(c => c !== itemUid);
    $("input#meerkeuze-" + meerkeuzeUid + "-items").val(JSON.stringify(meerkeuzeItems));
    deleteMeerkeuzeItemVraag(meerkeuzeUid, itemUid);
}

window.setMeerkeuzeItemSelected = function (meerkeuzeUid, itemUid) {
    $("#meerkeuze-" + meerkeuzeUid + "-item-" + itemUid + "-antwoord").focus();
}

window.toggleMeerkeuzeAbc = function(meerkeuzeUid, checked = false) {
    var checkboxEl = $("#meerkeuze-"+meerkeuzeUid+"-abc1");
    if (checked) {
        checkboxEl.prop("checked",1)
    }
    //console.log('toggleMeerkeuzeAbc', meerkeuzeUid, checkboxEl, checkboxEl.is(":checked"));
    if (checkboxEl.is(":checked")) {
        $("#v-meerkeuze-" + meerkeuzeUid + " .v-meerkeuze-items").removeClass("show-checkbox");
    } else {
        $("#v-meerkeuze-" + meerkeuzeUid + " .v-meerkeuze-items").addClass("show-checkbox");
    }
}

window.toggleMeerkeuzeInvulveld = function(meerkeuzeUid) {
    var checkboxEl = $("#meerkeuze-"+meerkeuzeUid+"-invulveld1");
    var toggleEl = $("#v-meerkeuze-"+meerkeuzeUid+"-invulveld");
    //console.log('toggleMeerkeuzeInvulveld', meerkeuzeUid, checkboxEl, checkboxEl.is(":checked"));
    if (checkboxEl.is(":checked")) {
        toggleEl.removeClass("hide");
    } else {
        toggleEl.addClass("hide");
    }
}

window.toggleMeerkeuzeAanvullende  = function(meerkeuzeUid) {
    var checkboxEl = $("#meerkeuze-"+meerkeuzeUid+"-aanvullende1");
    var toggleEl = $("#v-meerkeuze-"+meerkeuzeUid+"-aanvullende");
    //console.log('toggleMeerkeuzeAanvullende', meerkeuzeUid, checkboxEl, checkboxEl.is(":checked"));
    if (checkboxEl.is(":checked")) {
        toggleEl.removeClass("hide");
    } else {
        toggleEl.addClass("hide");
    }
}
