window.Woordenlijst = class Woordenlijst {
    items = [];
    elItems = null;

    constructor(items) {

        this.items = items;
        this.elItems = $('#woordenlijst-items-container');
        this.renderEl();
    }

    renderEl() {
        this.elItems.html('');
        this.items.forEach(item => {
            el = '<div class="woordenlijst-item d-flex justify-content-between" id="' + item.id + '">\n' +
                '<div class="bk-icon bk-icon--small sortable-handle flex-shrink-0 ui-sortable-handle"><i class="fal fa-grip-vertical"></i></div>\n' +
                '<div class="form-group flex-grow-1 ml-1 mr-1">\n' +
                '<input class="form-control" id="woordenlijst_' + item.id + '_woord"  type="text" value="' + item.woord + '" onchange="woordenlijst.updateValues(\'' + item.id + '\')">\n' +
                '</div><div class="form-group flex-grow-1 ml-1 mr-1">\n' +
                '<input class="form-control" id="woordenlijst_' + item.id + '_vertaling"  type="text" value="' + item.vertaling + '" onchange="woordenlijst.updateValues(\'' + item.id + '\')">\n' +
                '</div><div class="form-group flex-grow-1 ml-1 mr-1">\n' +
                '<input class="form-control" id="woordenlijst_' + item.id + '_zin"  type="text" value="' + item.zin + '" onchange="woordenlijst.updateValues(\'' + item.id + '\')">\n' +
                '</div>' +
                '<div class="bk-icon bk-icon--small flex-shrink-0" onclick="woordenlijst.deleteItem(\'' + item.id + '\')"><i class="far fa-times"></i></div>\n' +
                '</div>';
            this.elItems.append(el);
        });
        this.updateFormInputItems();
    }

    updateValues(id) {
        //console.log(id);
        //console.log(this.items);
        this.items = this.items.map(item => {
            //console.log(item.id);
            if (item.id == id) {
                //console.log("aaa");
                let woord = $("#woordenlijst_"+id+"_woord").val();
                let vertaling = $("#woordenlijst_"+id+"_vertaling").val();
                let zin = $("#woordenlijst_"+id+"_zin").val();

                return {id: item.id, woord: woord, vertaling: vertaling, zin:zin, sort_order: item.sort_order};
            }
            return item;
        });
        this.updateFormInputItems();
    }

    getItem(id) {
        //console.log('getItem', id);
        let item = this.items.filter(i => i.id == id);
        //console.log('item', item);
        if (item.length) return item[0];
        return null;
    }

    addItem(woord = '', vertaling = '', zin = '') {
        //console.log("addItem", )
        //console.log("addItem", this.items);
        let id = window.uid();
        sort_order = this.items.length;
        let item = {id: id, woord: woord, vertaling:vertaling, zin:zin, sort_order: sort_order};
        this.items.push(item);
        this.renderEl()
        $("#lesmateriaal_text").val("");
    }

    deleteItem(id) {
        //console.log("deleteItem", id);
        this.items = this.items.filter(i => i.id != id);
        this.renderEl();
    }

    reorderItems(ids) {
        //console.log("reorderItems", ids);
        let item;
        let itemsNew = [];
        let teller = 0;
        ids.forEach((id, index) => {
            item = this.getItem(id);
            itemsNew.push({id: item.id, woord: item.woord, vertaling: item.vertaling, zin: item.zin, sort_order: index});
        });
        this.items = itemsNew;
        this.updateFormInputItems();
        //console.log('reorderItems', this.items);
    }

    updateFormInputItems() {
        //console.log('updateFormInputItems');
        $('#woordenlijst-items').val(JSON.stringify(this.items));
    }


}
