window.kwp = [];
window.kwpEventListener;

$(document).on('change', 'select.kruiswoordpuzzel-aantal_x', function () {
    let uid = $(this).closest(".properties").attr("data-uid");
    let aantalCelX = $(this).val();
    window.kwp[uid].renderCelObjects(window.kwp[uid].gCelObjects, aantalCelX, window.kwp[uid].gAantalCelY);
    window.kwp[uid].gAantalCelX = aantalCelX;
    window.kwp[uid].renderCellen(this.gCelObjects);
});

$(document).on('change', 'select.kruiswoordpuzzel-aantal_y', function () {
    let uid = $(this).closest(".properties").attr("data-uid");
    let aantalCelY = $(this).val();
    window.kwp[uid].renderCelObjects(window.kwp[uid].gCelObjects, window.kwp[uid].gAantalCelX, aantalCelY);
    window.kwp[uid].gAantalCelY = aantalCelY;
    window.kwp[uid].renderCellen(this.gCelObjects);
});

$(document).on('change', 'input.kruiswoordpuzzel-nummer', function () {
    let uid = $(this).closest(".properties").attr("data-uid");
    let text = $(this).val();
    let horizontaal = $(this).attr("data-horizontaal");
    let nummer = $(this).attr("data-nummer");
    window.kwp[uid].gVragen = window.kwp[uid].gVragen.map(v => {
       if (v.horizontaal == horizontaal && v.nummer == nummer) {
           v.text = text;
       }
       return v;
    });
    //console.log('window.kwp[uid].gVragen', window.kwp[uid].gVragen);
    window.kwp[uid].updateFormInputVragen();
});

$(document).click(function (event) {
    let clickedEl = $(event.target);

    if (clickedEl.hasClass('v-kruiswoordpuzzel__cel__letter')) {
        //console.log("click op v-kruiswoordpuzzel__cel__letter");
        $('input[type=text], textarea').blur();
        let uid = clickedEl.closest(".v-kruiswoordpuzzel").attr('data-uid');
        Object.keys(window.kwp).filter(k => k !== uid).forEach(key => {
            window.kwp[key].deselectWoord();
            window.kwp[key].gCurrentCelX = 0;
            window.kwp[key].gCurrentCelY = 0;
        });
        $('input.kruiswoordpuzzel-nummer').blur();
        let kwp = window.kwp[uid];
        let celX = clickedEl.closest(".v-kruiswoordpuzzel__cel").data('x');
        let celY = clickedEl.closest(".v-kruiswoordpuzzel__cel").data('y');
        if (celX == kwp.gCurrentCelX && celY == kwp.gCurrentCelY) {
            kwp.gDirHorizontaal = kwp.gDirHorizontaal ? 0 : 1;
        } else {
            kwp.gCurrentCelX = celX;
            kwp.gCurrentCelY = celY;
        }
        kwp.selectWoord(kwp.gCurrentCelX, kwp.gCurrentCelY);
        if (window.kwpEventListener) {
            document.removeEventListener('keydown', window.kwpEventListener);
        }
        document.addEventListener('keydown', window.kwpEventListener = window.kwpKeydownCelSelected.bind(event, kwp), false);
    } else {
        //console.log('removeEventListener', typeof window.kwp);
        Object.keys(window.kwp).forEach(key => {
            window.kwp[key].deselectWoord();
            window.kwp[key].gCurrentCelX = 0;
            window.kwp[key].gCurrentCelY = 0;
        });
        document.removeEventListener('keydown', window.kwpEventListener);
        window.kwpEventListener = null;

    }
});

window.kwpKeydownCelSelected = function(kwp, keyboardEvent) {
    // //console.log('kwpKeydownCelSelected keyboardEvent', keyboardEvent);
    //console.log('kwpKeydownCelSelected kwp', kwp);
    //console.log('key' , keyboardEvent.key);
    if (kwp.gCurrentCelX > 0 && kwp.gCurrentCelY > 0) {
        let key = keyboardEvent.key;
        if (key == 'ArrowRight') {
            kwp.setNextLetterCel(kwp.gCurrentCelX, kwp.gCurrentCelY, kwp.gDirHorizontaal);
        }
        if (key == 'ArrowLeft') {
            kwp.setPrevLetterCel(kwp.gCurrentCelX, kwp.gCurrentCelY, kwp.gDirHorizontaal);
        }
        if (key == 'Backspace') {
            kwp.updateCelWithLetter(kwp.gCurrentCelX, kwp.gCurrentCelY, '');
            kwp.renderCellen(kwp.renderCelObjectsNummer(kwp.gCelObjects));
            kwp.setPrevLetterCel(kwp.gCurrentCelX, kwp.gCurrentCelY, kwp.gDirHorizontaal);
        }
        if (key.length == 1) {
            if (key.match(/[A-Za-z#]/)) {
                kwp.updateCelWithLetter(kwp.gCurrentCelX, kwp.gCurrentCelY, key);
                kwp.renderCellen(kwp.renderCelObjectsNummer(kwp.gCelObjects));
                kwp.setNextLetterCel(kwp.gCurrentCelX, kwp.gCurrentCelY, kwp.gDirHorizontaal);
            } else if (key == '@') {
                kwp.updateCelWithLetter(kwp.gCurrentCelX, kwp.gCurrentCelY, key);
                kwp.renderCellen(kwp.renderCelObjectsNummer(kwp.gCelObjects));
                kwp.setNextLetterCel(kwp.gCurrentCelX, kwp.gCurrentCelY, kwp.gDirHorizontaal);
            }
        }
    }
}

window.Kruiswoordpuzzel = class Kruiswoordpuzzel {
    gUid = null;
    gMatrixEl = null;
    gVragenXEl = null;
    gVragenYEl = null;
    gDirHorizontaal = 1;
    gAantalCelX = 0;
    gAantalCelY = 0;
    gCelObjects = [];
    gCurrentCelX = 0;
    gCurrentCelY = 0;
    gVragen = [];

    constructor(gUid, gAantalCelX, gAantalCelY, gCelObjects, gVragen) {
        this.gUid = gUid;
        this.gAantalCelX = gAantalCelX;
        this.gAantalCelY = gAantalCelY;
        this.gCelObjects = gCelObjects;
        this.gVragen = gVragen;
        this.gMatrixEl = $(".v-kruiswoordpuzzel[data-uid='" + gUid + "']");
    }

    updateFormInputCellen() {
        $('#kruiswoordpuzzel-' + this.gUid + '-cellen').val(JSON.stringify(this.gCelObjects));
    }

    renderCellen() {
        let celEl;
        let nummer;
        this.gMatrixEl.html('');
        this.gMatrixEl.css('grid-template-columns', 'repeat(' + this.gAantalCelX + ', 40px)');
        this.gMatrixEl.css('grid-template-rows', 'repeat(' + this.gAantalCelY + ', 40px)');
        this.gCelObjects.forEach(cel => {
            nummer = cel.nummer_x ? cel.nummer_x : cel.nummer_y ? cel.nummer_y : '';
            celEl = '<div class="v-kruiswoordpuzzel__cel" data-letter="' + cel.letter + '" data-x="' + cel.x + '" data-y="' + cel.y + '" data-nummer_x="' + cel.nummer_x + '" data-nummer_y="' + cel.nummer_y + '">' +
                '<div class="v-kruiswoordpuzzel__cel__debug">' + cel.x + ',' + cel.y + '</div>' +
                '<div class="v-kruiswoordpuzzel__cel__nummer">' + nummer + '</div>' +
                '<div class="v-kruiswoordpuzzel__cel__fout"></div>' +
                '<div class="v-kruiswoordpuzzel__cel__letter">' + cel.letter + '</div>' +
                '</div>';
            this.gMatrixEl.append(celEl);
        });
        setTimeout(() => {
            this.updateFormInputCellen();
            this.renderVragen();
        }, 500); //properties.blade.php request moet verwerkt zijn!
    }

    updateFormInputVragen() {
        $('#kruiswoordpuzzel-' + this.gUid + '-vragen').val(JSON.stringify(this.gVragen));
    }

    renderVragen() {
        //console.log("renderVragen", this.gCelObjects);
        let rowEl;
        let currentVragen = this.gVragen;
        //console.log('currentVragen', currentVragen);
        let currentVraag;
        let currentText;
        this.gVragen = [];
        this.gVragenXEl = $("#kruiswoordpuzzel-vragen-x-" + this.gUid);
        this.gVragenYEl = $("#kruiswoordpuzzel-vragen-y-" + this.gUid);
        this.gVragenXEl.html('');
        this.gVragenYEl.html('');
        this.gCelObjects.forEach(cel => {
            if (cel.nummer_x) {
                currentVraag = this.getVraag(currentVragen, 1, cel.nummer_x);
                currentText = currentVraag ? currentVraag.text : '';
                rowEl = '<div class="kruiswoordpuzzel__row d-flex">' +
                    '<div class="kruiswoordpuzzel__nummer flex-shrink-0">' + cel.nummer_x + '</div>' +
                    '<div class="kruiswoordpuzzel__input flex-grow-1">' +
                    '<div class="form-group">' +
                    '<input id="kruiswoordpuzzel-' + this.gUid + '-nummer_x-' + cel.nummer_x + '" class="form-control kruiswoordpuzzel-nummer" name="kruiswoordpuzzel-' + this.gUid + '-nummer_x-' + cel.nummer_x + '" type="text" value="' + currentText + '" data-horizontaal="1" data-nummer="' + cel.nummer_x + '">' +
                    '<div class="validator-message"></div>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
                this.gVragenXEl.append(rowEl);
                this.gVragen.push({horizontaal: 1, nummer: cel.nummer_x, text: currentText});
            }
            if (cel.nummer_y) {
                currentVraag = this.getVraag(currentVragen, 0, cel.nummer_y);
                currentText = currentVraag ? currentVraag.text : '';
                rowEl = '<div class="kruiswoordpuzzel__row d-flex">' +
                    '<div class="kruiswoordpuzzel__nummer flex-shrink-0">' + cel.nummer_y + '</div>' +
                    '<div class="kruiswoordpuzzel__input flex-grow-1">' +
                    '<div class="form-group">' +
                    '<input id="kruiswoordpuzzel-' + this.gUid + '-nummer_y-' + cel.nummer_y + '" class="form-control kruiswoordpuzzel-nummer" name="kruiswoordpuzzel-' + this.gUid + '-nummer_y-' + cel.nummer_y + '" type="text" value="' + currentText + '" data-horizontaal="0" data-nummer="' + cel.nummer_y + '">' +
                    '<div class="validator-message"></div>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
                this.gVragenYEl.append(rowEl);
                this.gVragen.push({horizontaal: 0, nummer: cel.nummer_y, text: currentText});
            }
        });
        this.updateFormInputVragen();
    }

    renderCelObjects(currentCelObjects, aantalX, aantalY) {
        let celObjects = this.renderCelObjectsNummer(this.renderCelObjectsLetter(currentCelObjects, aantalX, aantalY));
        this.gCelObjects = celObjects;
    }

    renderCelObjectsLetter(currentCelObjects, aantalX, aantalY) {
        //console.log('renderCelObjectsLetter', currentCelObjects, aantalX, aantalY);
        let celObjects = [];
        let letter;
        let currentCel;
        for (let y = 1; y <= aantalY; y++) {
            for (let x = 1; x <= aantalX; x++) {
                currentCel = this.getCel(currentCelObjects, x, y);
                letter = currentCel ? currentCel.letter : '';
                celObjects.push({x: x, y: y, letter: letter, nummer_x: 0, nummer_y: 0});
            }
        }
        return celObjects;
    }

    renderCelObjectsNummer(currentCelObjects) {
        //console.log('renderCelObjectsNummer', currentCelObjects);
        let celObjects = [];
        let nummer = 1;
        let nummerOphogen;
        currentCelObjects.forEach(cel => {
            nummerOphogen = false;
            cel.nummer_x = 0;
            cel.nummer_y = 0;
            if (this.isXStartOfWord(currentCelObjects, cel)) {
                cel.nummer_x = nummer;
                nummerOphogen = true;
            }
            if (this.isYStartOfWord(currentCelObjects, cel)) {
                cel.nummer_y = nummer;
                nummerOphogen = true;
            }
            if (nummerOphogen) {
                nummer++;
            }
            celObjects.push(cel);
        });
        return celObjects;
    }

    getVraag(vragen, horizontaal, nummer) {
        let vraag = vragen.filter(v => v.horizontaal == horizontaal && v.nummer == nummer);
        if (vraag.length) return vraag[0];
        return null;
    }

    getCel(celObjects, x, y) {
        let celObject = celObjects.filter(c => c.x === x && c.y === y);
        if (celObject.length) return celObject[0];
        return null;
    }

    updateCelWithLetter(celX, celY, key) {
        let celObjects = this.gCelObjects.map(c => {
            if (c.x === celX && c.y === celY) {
                return {x: c.x, y: c.y, letter: key, nummer_x: c.nummer_x, nummer_y: c.nummer_y};
            }
            return c;
        });
        this.gCelObjects = celObjects;
    }

    isXStartOfWord(celObjects, cel) {
        //console.log('this.isXStartOfWord', celObjects, cel);
        if (cel.letter == "@") {
            return false;
        }
        let celXPrev = this.getCel(celObjects, cel.x - 1, cel.y);
        if (celXPrev && celXPrev.letter != "@") {
            return false;
        }
        let celXNext = this.getCel(celObjects, cel.x + 1, cel.y);
        if (celXNext && celXNext.letter != "@") {
            return true;
        }
        return false;
    }

    isYStartOfWord(celObjects, cel) {
        //console.log('this.isYStartOfWord', celObjects, cel);
        if (cel.letter == "@") {
            return false;
        }
        let celYPrev = this.getCel(celObjects, cel.x, cel.y - 1);
        if (celYPrev && celYPrev.letter != "@") {
            return false;
        }
        let celYNext = this.getCel(celObjects, cel.x, cel.y + 1);
        if (celYNext && celYNext.letter != "@") {
            return true;
        }
        return false;
    }

    selectWoord(celX, celY) {
        //console.log('selectWoord', celX, celY);
        this.deselectWoord();
        let celObject = this.getCel(this.gCelObjects, celX, celY);
        if (celObject.letter == "@") {
            return;
        }
        if (celX && celY) {
            if (this.gDirHorizontaal == 1) {
                let firstXOfWord = this.getFirstXOfWord(celX, celY);
                let lastCelXOfWord = this.getLastXOfWord(celX, celY);
                for (let x = firstXOfWord; x <= lastCelXOfWord; x++) {
                    this.selectCel(x, celY);
                }
            } else {
                let firstYOfWord = this.getFirstYOfWord(celX, celY);
                let lastCelYOfWord = this.getLastYOfWord(celX, celY);
                for (let y = firstYOfWord; y <= lastCelYOfWord; y++) {
                    this.selectCel(celX, y);
                }
            }
        }
    }

    deselectWoord() {
        //console.log('deselectWoord', this.gMatrixEl);
        this.gMatrixEl.find(".v-kruiswoordpuzzel__cel").removeClass("v-kruiswoordpuzzel__cel--selected").removeClass("v-kruiswoordpuzzel__cel--current");
    }

    selectCel(celX, celY) {
        if (celX == this.gCurrentCelX && celY == this.gCurrentCelY) {
            this.gMatrixEl.find(".v-kruiswoordpuzzel__cel[data-x='" + celX + "'][data-y='" + celY + "']").addClass("v-kruiswoordpuzzel__cel--current");
        } else {
            this.gMatrixEl.find(".v-kruiswoordpuzzel__cel[data-x='" + celX + "'][data-y='" + celY + "']").addClass("v-kruiswoordpuzzel__cel--selected");
        }
    }

    getNextLetterCel(celX, celY, dirHorizontaal) {
        //console.log('getNextLetterCel', celX, celY, dirHorizontaal);
        if (dirHorizontaal) {
            let nextCelX = celX;
            let nextCelY = celY;
            let t = 0;
            while (true) {
                t = t + 1;
                if (t > 25) {
                    //console.log('while1 > 25');
                    break;
                }
                nextCelX++;
                if (nextCelX > this.gAantalCelX) {
                    nextCelX = 1;
                    nextCelY++;
                    if (nextCelY > this.gAantalCelY) {
                        nextCelY = 1;
                    }
                }
                let cel = this.getCel(this.gCelObjects, nextCelX, nextCelY);
                if (cel && cel.letter != "@") {
                    let celXPrev = this.getCel(this.gCelObjects, cel.x - 1, cel.y);
                    let celXNext = this.getCel(this.gCelObjects, cel.x + 1, cel.y);
                    if ((celXPrev && celXPrev.letter != "@") || (celXNext && celXNext.letter != "@")) {
                        break;
                    }
                }
            }
            return {celX: nextCelX, celY: nextCelY};
        } else {
            let nextCelX = celX;
            let nextCelY = celY;
            let t = 0;
            while (true) {
                t = t + 1;
                if (t > 25) {
                    //console.log('while1 > 25');
                    break;
                }
                nextCelY++;
                if (nextCelY > this.gAantalCelY) {
                    nextCelY = 1;
                    nextCelX++;
                    if (nextCelX > this.gAantalCelX) {
                        nextCelX = 1;
                    }
                }
                let cel = this.getCel(this.gCelObjects, nextCelX, nextCelY);
                if (cel && cel.letter != "@") {
                    let celYPrev = this.getCel(this.gCelObjects, cel.x, cel.y - 1);
                    let celYNext = this.getCel(this.gCelObjects, cel.x, cel.y + 1);
                    if ((celYPrev && celYPrev.letter != "@") || (celYNext && celYNext.letter != "@")) {
                        break;
                    }
                }
            }
            return {celX: nextCelX, celY: nextCelY};
        }
    }

    getPrevLetterCel(celX, celY, dirHorizontaal) {
        //console.log('getPrevLetterCel', celX, celY, dirHorizontaal);
        if (dirHorizontaal) {
            let nextCelX = celX;
            let nextCelY = celY;
            let t = 0;
            while (true) {
                t = t + 1;
                if (t > 25) {
                    //console.log('while1 > 25');
                    break;
                }
                nextCelX--;
                if (nextCelX < 1) {
                    nextCelX = this.gAantalCelX;
                    nextCelY--;
                    if (nextCelY < 1) {
                        nextCelY = this.gAantalCelY;
                    }
                }
                let cel = this.getCel(this.gCelObjects, nextCelX, nextCelY);
                if (cel && cel.letter != "@") {
                    let celXPrev = this.getCel(this.gCelObjects, cel.x - 1, cel.y);
                    let celXNext = this.getCel(this.gCelObjects, cel.x + 1, cel.y);
                    if ((celXPrev && celXPrev.letter != "@") || (celXNext && celXNext.letter != "@")) {
                        break;
                    }
                }
            }
            return {celX: nextCelX, celY: nextCelY};
        } else {
            let nextCelX = celX;
            let nextCelY = celY;
            let t = 0;
            while (true) {
                t = t + 1;
                if (t > 25) {
                    //console.log('while1 > 25');
                    break;
                }
                nextCelY--;
                if (nextCelY < 1) {
                    nextCelY = this.gAantalCelY;
                    nextCelX--;
                    if (nextCelX < 1) {
                        nextCelX = this.gAantalCelX;
                    }
                }
                let cel = this.getCel(this.gCelObjects, nextCelX, nextCelY);
                if (cel && cel.letter != "@") {
                    let celYPrev = this.getCel(this.gCelObjects, cel.x, cel.y - 1);
                    let celYNext = this.getCel(this.gCelObjects, cel.x, cel.y + 1);
                    if ((celYPrev && celYPrev.letter != "@") || (celYNext && celYNext.letter != "@")) {
                        break;
                    }
                }
            }
            return {celX: nextCelX, celY: nextCelY};
        }
    }

    getCelEl(kruiswoordpuzzelEl, celX, celY) {
        //console.log('this.getCel', kruiswoordpuzzelEl, celX, celY);
        let celEl = kruiswoordpuzzelEl.find(".v-kruiswoordpuzzel__cel[data-x='" + celX + "'][data-y='" + celY + "']");
        if (celEl.length) {
            return celEl;
        }
        return null;
    }

    getCelObject(kruiswoordpuzzelEl, celX, celY) {
        let celEl = kruiswoordpuzzelEl.find(".v-kruiswoordpuzzel__cel[data-x='" + celX + "'][data-y='" + celY + "']");
        if (!celEl.length) {
            return null;
        }
        let l = celEl.attr("data-letter");
        if (celEl.length) {
            return {celX: celX, celY: celY, letter: l};
        }
        return null;
    }

    setNextLetterCel(CelX, CelY, DirHorizontaal) {
        let nextLetterCel = this.getNextLetterCel(CelX, CelY, DirHorizontaal);
        this.gCurrentCelX = nextLetterCel.celX;
        this.gCurrentCelY = nextLetterCel.celY;
        this.selectWoord(this.gCurrentCelX, this.gCurrentCelY, DirHorizontaal);
    }

    setPrevLetterCel(CelX, CelY, DirHorizontaal) {
        let nextLetterCel = this.getPrevLetterCel(CelX, CelY, DirHorizontaal);
        this.gCurrentCelX = nextLetterCel.celX;
        this.gCurrentCelY = nextLetterCel.celY;
        this.selectWoord(this.gCurrentCelX, this.gCurrentCelY, DirHorizontaal);
    }

    getFirstXOfWord(celX, celY) {
        let firstCelX = celX;
        let tryCelX = firstCelX;
        let t = 0;
        while (true) {
            t = t + 1;
            if (t > 25) {
                //console.log('while5 > 25');
                break;
            }
            tryCelX--;
            let prevCelX = this.getCel(this.gCelObjects, tryCelX, celY);
            if (prevCelX && prevCelX.letter != "@") {
                firstCelX = tryCelX;
                continue;
            }
            break;
        }
        return firstCelX;
    }


    getLastXOfWord(celX, celY) {
        let lastCelX = celX;
        let tryCelX = lastCelX;
        let t = 0;
        while (true) {
            t = t + 1;
            if (t > 25) {
                //console.log('while6 > 25');
                break;
            }
            tryCelX++;
            let nextCelX = this.getCel(this.gCelObjects, tryCelX, celY);
            if (nextCelX && nextCelX.letter != "@") {
                lastCelX = tryCelX;
                continue;
            }
            break;
        }
        //console.log('getLastXOfWord return', lastCelX);
        return lastCelX;
    }

    getFirstYOfWord(celX, celY) {
        let firstCelY = celY;
        let tryCelY = firstCelY;
        let t = 1;
        while (true) {
            t = t + 1;
            if (t > 25) {
                //console.log('while7 > 25');
                break;
            }
            tryCelY--;
            let prevCelY = this.getCel(this.gCelObjects, celX, tryCelY);
            if (prevCelY && prevCelY.letter != "@") {
                firstCelY = tryCelY;
                continue;
            }
            break;
        }
        return firstCelY;
    }

    getLastYOfWord(celX, celY) {
        let lastCelY = celY;
        let tryCelY = lastCelY;
        let t = 0;
        while (true) {
            t = t + 1;
            if (t > 25) {
                //console.log('while8 > 25');
                break;
            }
            tryCelY++;
            let nextCelY = this.getCel(this.gCelObjects, celX, tryCelY);
            if (nextCelY && nextCelY.letter != "@") {
                lastCelY = tryCelY;
                continue;
            }
            break;
        }
        return lastCelY;
    }
}
