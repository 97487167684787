window.volgorde = [];

window.Volgorde = class Volgorde {
    uid = null;
    items = [];
    elItemsProperties = null;
    elItemsVraag = null;

    constructor(uid, items) {
        this.uid = uid;
        this.items = items;
        this.elItemsProperties = $('#volgorde-' + this.uid + '-items-container');
        this.elItemsVraag = $('#v-volgorde-' + this.uid + '-items-container');
        this.renderEl();
    }

    renderEl() {
        this.elItemsProperties.html('');
        this.items.forEach(item => {
            el = '<div class="volgorde-item d-flex justify-content-between" id="' + item.uid + '">\n' +
                '<div class="bk-icon bk-icon--small sortable-handle flex-shrink-0 ui-sortable-handle"><i class="fal fa-grip-vertical"></i></div>\n' +
                '<div class="form-group flex-grow-1 ml-1 mr-1">\n' +
                '<input class="form-control" type="text" value="' + item.titel + '" onkeyup="volgorde[\'' + this.uid + '\'].onkeyupTitel(\'' + item.uid + '\', this.value)" onfocus="volgorde[\'' + this.uid + '\'].onfocusTitel(\'' + item.uid + '\')" onblur="volgorde[\'' + this.uid + '\'].onblurTitel(\'' + item.uid + '\')" onchange="volgorde[\'' + this.uid + '\'].updateTitel(\'' + item.uid + '\', this.value)">\n' +
                '<div class="validator-message"></div>\n' +
                '</div>\n' +
                '<div class="bk-icon bk-icon--small flex-shrink-0" onclick="volgorde[\'' + this.uid + '\'].deleteItem(\'' + item.uid + '\')"><i class="far fa-times"></i></div>\n' +
                '</div>';
            this.elItemsProperties.append(el);
        });
        this.updateFormInputItems();
    }

    updateTitel(uid, titel) {
        this.items = this.items.map(i => {
            if (i.uid === uid) {
                return { uid: i.uid, titel: titel, sort_order: i.sort_order };
            }
            return i;
        });
        this.updateFormInputItems();
    }

    onfocusTitel(uid) {
        //console.log('onfocusTitel', uid);
        $("#v-" + uid).find(".v-volgorde__item__left").addClass("focus");
    }

    onblurTitel(uid) {
        //console.log('onblurTitel');
        $("#v-" + uid).find(".v-volgorde__item__left").removeClass("focus");
    }

    onkeyupTitel(uid, titel) {
        //console.log('onkeyupTitel', uid, titel);
        $("#v-" + uid).find(".v-volgorde__item__left").html(titel);
    }

    getItem(uid) {
        //console.log('getItem', uid);
        let item = this.items.filter(i => i.uid === uid);
        //console.log('item', item);
        if (item.length) return item[0];
        return null;
    }

    addItem() {
        //console.log("addItem", this.items);
        let uid = window.uid();
        let item = { uid: uid, titel: '', sort_order: 0 };
        this.items.push(item);
        this.renderEl()
    }

    deleteItem(uid) {
        //console.log("deleteItem", uid);
        this.items = this.items.filter(i => i.uid !== uid);
        this.renderEl();
    }

    reorderItems(Uids) {
        //console.log("reorderItems", Uids);
        let item;
        let itemsNew = [];
        let teller = 0;
        Uids.forEach((uid, index) => {
            item = this.getItem(uid);
            itemsNew.push({ uid: item.uid, titel: item.titel, sort_order: index });
        });
        this.items = itemsNew;
        this.updateFormInputItems();
        //console.log('reorderItems', this.items);
    }

    updateFormInputItems() {
        //console.log('updateFormInputCellen', this.cellen);
        $('#volgorde-' + this.uid + '-items').val(JSON.stringify(this.items));
        this.renderElVraag();
    }

    renderElVraag() {
        this.elItemsVraag.html('');
        this.items.forEach(item => {
            el = '<div class="v-volgorde__item d-flex" id="v-' + item.uid + '">\n' +
                '<div class="v-volgorde__item__left">' + item.titel + '</div>\n' +
                '</div>';
            this.elItemsVraag.append(el);
        });
    }
}
