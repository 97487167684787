window.bk_nav = function () {
    if (Cookies.get('main-nav-small') == 'true') {
        $("#app").addClass("bk-nav-small");
    }

    $(document).on("click", ".bk-nav__group__header", function () {
        $(this).closest(".bk-nav__group").children(".bk-nav__group__items").slideToggle(300);
        $(this).closest(".bk-nav__group").toggleClass("bk-nav__group--open");
    });
    $(".bk-nav__item__icon--toggle").each(function () {
        //console.log($(this).closest(".bk-nav__group").find(".bk-nav__group__items"));
    });
    $(document).on("click", "#bkAppMainToggle", function () {
        if ($("#app").hasClass("bk-nav-small")) {
            Cookies.set('main-nav-small', 'false');
            $("#app").removeClass("bk-nav-small");
        } else {
            Cookies.set('main-nav-small', 'true');
            $("#app").addClass("bk-nav-small");
        }
    });
}

$(document).ready(function () {
    window.bk_nav();
});

