require('./layout');
require('./vue');

allCkEditors = [];

window.getCKEditor = function(name) {
    for (var i = 0; i < allCkEditors.length; i++) {
        //if (allCkEditors[i].sourceElement.id === name) return allCkEditors[i];
        if (allCkEditors[i] === name) return allCkEditors[i];
    }

    return null;
}

$(document).ready(function() {
});

$(document).on("click", ".form-submit", function (e) {
    formId = $(this).data('form-id')
    $("#" + formId).submit();
});

$(document).on("submit", "form", function (event) {
    event.stopPropagation();
    event.preventDefault();

    for (var i = 0; i < allCkEditors.length; i++) {
        CKEDITOR.instances[allCkEditors[i]].updateElement();
        $('#v-'+allCkEditors[i]).html( $("#"+allCkEditors[i]).val() );
    }

    let form = $(this);
    let formId = "#" + form.attr("id");
    let formUrl = form.data("url");

    $(formId).find("textarea").each(function () {
        //console.log($(this).val())
    });

    if ($(formId).hasClass('submitted')) {
        // return; //turned off for development
    }
    $(formId).addClass('submitted');



    let data = new FormData(form[0]);
    console.log(data);
    let request = $.ajax({
        url: formUrl,
        method: "POST",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        enctype: 'multipart/form-data',
        data: data,
        cache: false,
        async: true,
        contentType: false,
        processData: false
    });
    request.done(function (response) {
        console.log(response);
        if (response.status == "ok") {
            //ok
            //redirect
            if (response.redirect) {
                window.location.href = response.redirect;
            } else {
                $(formId).removeClass('submitted');
                $(formId + ' .form-group.has-error').each(function () {
                    $(this).removeClass('has-error');
                });
                if (response.message) {
                    notify("success", 'Success', response.message);
                }
                if (response.actions) {
                    response.actions.forEach(action => {
                        if (action.name === 'sortableListsNewItem') {
                            var prependElement = $('#s-l-'+ action.model);
                            dataLevel = 1;
                            if (action.item.parent_id) {
                                dataLevel = 2; //todo or 3+
                                prependElement = prependElement.find('#sli-' + action.item.parent_id);
                                prependElement.addClass('s-l-open');
                                var ul = prependElement.children('ul');
                                if (!ul.length) {
                                    //add plus/min
                                    prependElement.find(">:first-child").prepend('<span class="s-l-opener" onclick="toggleThisSLOperer(this)" style="float: none; display: block; background-position: center center; background-repeat: no-repeat;"><i class="fa fa-minus"></i></span>');
                                    //add ul
                                    prependElement.append('<ul data-level="' + dataLevel + '"></ul>');
                                    ul = prependElement.children('ul');
                                }
                                prependElement = ul;
                            }

                            prependElement.append('<li id="sli-' + action.item.id + '" data-level="' + dataLevel + '"><div class="d-flex sl-ignore"><div class="text truncate title sl-ignore">' + action.item.titel + '</div> <div class="d-flex align-items-center justify-content-end actions sl-ignore"><div onclick="slEdit(0, ' + action.item.id + ')" class="bk-icon sl-ignore"><i class="fa-regular fa-plus sl-ignore"></i></div> <div onclick="slEdit(' + action.item.id + ')" class="bk-icon sl-ignore"><i class="fa-regular fa-pen-to-square sl-ignore"></i></div> <div onclick="slRemove(' + action.item.id + ')" class="bk-icon bk-icon--remove sl-ignore"><i class="fa-regular fa-trash-can sl-ignore"></i></div></div></div></li>');
                        }
                        if (action.name === 'sortableListsUpdateItem') {
                            $('#s-l-'+ action.model).find('#sli-' + action.item.id).find(".text").first().html(action.item.titel);
                        }
                        if (action.name === 'sortableListsNewItemLesmateriaal') {
                            if (action.item.id) {

                                $("#formOpdracht").data("url","/api"+$("#urlPrefix").val()+"/"+action.item.id+"/edit");
                            }
                            var prependElement = $('#s-l-'+ action.model);
                            dataLevel = 1;
                            if (action.item.parent_id) {
                                dataLevel = 2; //todo or 3+
                                prependElement = prependElement.find('#sli-' + action.item.parent_id);
                                prependElement.addClass('s-l-open');
                                var ul = prependElement.children('ul');
                                if (!ul.length) {
                                    //add plus/min
                                    prependElement.find(">:first-child").prepend('<span class="s-l-opener" onclick="toggleThisSLOperer(this)" style="float: none; display: block; background-position: center center; background-repeat: no-repeat;"><i class="fa fa-minus"></i></span>');
                                    //add ul
                                    prependElement.append('<ul data-level="' + dataLevel + '"></ul>');
                                    ul = prependElement.children('ul');
                                }
                                prependElement = ul;
                            }
                            prependElement.append(action.slNewItem);
                            //console.log(action.slNewItem);
                        }
                        if (action.name === 'sortableListsUpdateItemLesmateriaal') {
                            //console.log('sortableListsUpdateItemLesmateriaal');

                            $('#s-l-'+ action.model).find('#sli-' + action.item.id).find('.text.title').first().html(action.item.titel);
                            $('#s-l-'+ action.model).find('#sli-' + action.item.id).find('.text.soort').first().html(action.item.soort);
                            $('#s-l-'+ action.model).find('#sli-' + action.item.id).find('.opdracht_koppeling').first().html(action.item.koppeling);
                            $('#s-l-'+ action.model).find('#sli-' + action.item.id).find('.opdracht_leerlijn').first().html(action.item.leerlijn);
                        }
                        if (action.name === 'bkAppModalClose') {
                            bkAppModalClose();
                            allCkEditors = [];
                        }
                        if (action.name === 'dataTableReload') {
                            dataTable.ajax.reload(null, false);
                        }
                    });
                }
            }
        } else {
            $(formId + ' .form-group.has-error').each(function () {
                $(this).removeClass('has-error');
            });
            $(formId).removeClass('submitted');
            //error
            $(formId + ' .is-invalid').each(function () {
                $(this).removeClass('is-invalid');
                $(this).siblings('.validator-message').html('');
            });
            if (response.validator) {
                $.each(response.validator, function (key, value) {
                    $(formId).find("input[name=" + key + "]").closest('.form-group').addClass('has-error').find('.validator-message').html(value);
                    $(formId).find("textarea[name=" + key + "]").closest('.form-group').addClass('has-error').find('.validator-message').html(value);
                    $(formId).find("select[name=" + key + "]").closest('.form-group').addClass('has-error').find('.validator-message').html(value);
                    $(formId).find("select[id=" + key + "]").closest('.form-group').addClass('has-error').find('.validator-message').html(value);
                });
            }
            if (response.message) {
                notify("error", 'Fout', response.message);
            }
        }
    });
    request.fail(function (jqXHR, textStatus, errorThrown) {
        notify("error", 'Fout', errorThrown);
    });
});

$(document).on("submit", "form", function (event) {
    event.stopPropagation();
    event.preventDefault();
});

window.fuUploadFile = function(name) {
    let file = document.getElementById("fu-" + name + "-file").files[0];
    let module = $("#fu-" + name + "-file").data("module");
    let mimes = $("#fu-" + name + "-file").data("mimes");
    $("#fu-" + name).css("display", "flex");
    $("#fu-" + name + "-progress").css("display", "flex");
    $("#fu-" + name + "-file-title").html(file.name);
    $("#fu-" + name + "-file-preview").attr('src', '/img/white.jpg');
    $("#fu-" + name + "-file-size").html(Math.round(file.size / 1000) + ' KB');
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("module", module);
    formdata.append("mimes", mimes);
    let xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", event => fuProgressHandler(event, name), false);
    xhr.addEventListener("load", event => fuCompleteHandler(event, name), false);
    xhr.addEventListener("error", event => fuErrorHandler(event, name), false);
    xhr.addEventListener("abort", event => fuAbortHandler(event, name), false);
    xhr.open("POST", "/file/upload");
    xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
    xhr.responseType = 'json';
    xhr.send(formdata);
}

window.fuProgressHandler = function(event, name) {
    let percent = (event.loaded / event.total) * 100;
    $("#fu-" + name + "-progress-bar").attr('aria-valuenow', Math.round(percent)).css('width', Math.round(percent) + '%');
    $("#fu-" + name + "-file-status").innerHTML = Math.round(percent) + "% geüpload...";
}

window.fuCompleteHandler = function(event, name) {
    if (event.target.response.status == "ok") {
        $("#fu-" + name).closest('.form-group').removeClass('has-error');
        $("#fu-" + name + "-file-title").html(event.target.response.file.fullname);
        $("#fu-" + name + "-file-status").innerHTML = event.target.response.message;
        $("#fu-" + name + "-file-preview").attr('src', event.target.response.file.url);
        $("#" + name + "").val(event.target.response.file.id);
        if (event.target.response.message) {
            // notify("success", 'Succes', event.target.response.message);
        }
    } else {
        if (event.target.response.validator) {
            $.each(event.target.response.validator, function (key, value) {
                $("#fu-" + name).closest('.form-group').addClass('has-error').find('.validator-message').html(value);
            });
            $("#fu-" + name + "-file-preview").attr('src', '/img/error.jpg');
        }
        if (event.target.response.message) {
            // notify("error", 'Fout', event.target.response.message);
        }
    }
}

window.fuErrorHandler = function(event, name) {
    $("#fu-" + name + "-file-status").innerHTML = "Upload Failed";
}

window.fuAbortHandler = function(event, name) {
    $("#fu-" + name + "-file-status").innerHTML = "Upload Aborted";
}

window.fuRemoveFile = function(name) {
    $("#fu-" + name).css("display", "none");
    $("#" + name).val('');
    $("#fu-" + name + "-file").val('');
    $("#fu-" + name + "-file-status").innerHTML = '';
    $("#fu-" + name + "-file-preview").attr('src', '/img/white.jpg');
    $("#fu-" + name + "-file-title").html('');
}

window.selectFileWithCKFinder = function(name, startupPath) {
    CKFinder.modal({
        rememberLastFolder :  false,
        startupPath: startupPath,
        startupFolderExpanded:  true,
        chooseFiles: true,
        onInit: function( finder ) {
            finder.on( 'files:choose', function( evt ) {
                var file = evt.data.files.first();
                //console.log(file);
                $("#fu-" + name).css("display", "flex");
                $("#fu-" + name + "-file-title").html(file.attributes.name);
                $("#fu-" + name + "-file-preview").attr('src', file.getUrl());
                $("#fu-" + name + "-file-size").html(Math.round(file.attributes.size) + ' KB');
                $("#" + name + "").val(file.getUrl());
            } );

            finder.on( 'file:choose:resizedImage', function( evt ) {
                $("#fu-" + name).css("display", "flex");
                // $("#fu-" + name + "-file-title").html(file.attributes.name);
                $("#fu-" + name + "-file-preview").attr('src', evt.data.resizedUrl);
                // $("#fu-" + name + "-file-size").html(Math.round(file.attributes.size) + ' KB');
                $("#" + name + "").val(evt.data.resizedUrl);
            } );
        }
    });
}


