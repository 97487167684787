window.Gekoppeld = class Gekoppeld {
    startupPath = null;
    items = [];
    elItems = null;

    constructor(startupPath, items) {
        this.startupPath = startupPath;
        this.items = items;
        this.elItems = $('#gekoppeld-items-container');
        this.renderEl();
    }

    renderEl() {
        this.elItems.html('');
        let icon;
        let icons = {'1': 'text', '2': 'afbeelding', '3': 'video', '4': 'audio'}
        this.items.forEach(item => {
            icon = icons[item.type]
            let readonly = '';
            if (item.type == 1) {
                readonly = 'readonly';
            }
            el = '<div class="gekoppeld-item d-flex justify-content-between" id="' + item.uid + '">\n' +
                '<div class="bk-icon bk-icon--small sortable-handle flex-shrink-0 ui-sortable-handle"><i class="fal fa-grip-vertical"></i></div>\n' +
                '<div class="form-group flex-grow-1 ml-1 mr-1 form-group--' + icon + '">\n' +
                '<input class="form-control" '+readonly+' id="gekoppeld_' + item.uid + '_titel"  type="text" value="' + item.titel + '" placeholder="' + item.placeholder + '" onchange="gekoppeld.updateTitel(\'' + item.uid + '\', this.value)">\n' +
                '</div>' +
                '<div class="bk-icon bk-icon--small flex-shrink-0" onclick="gekoppeld.deleteItem(\'' + item.uid + '\')"><i class="far fa-times"></i></div>\n' +
                '</div>';
            this.elItems.append(el);
        });
        this.updateFormInputItems();
    }

    updateTitel(uid, titel) {
        this.items = this.items.map(item => {
            if (item.uid === uid) {
                return {uid: item.uid, type: item.type, titel: titel, placeholder: item.placeholder, lesmateriaal_text_id: item.lesmateriaal_text_id, afbeelding: item.afbeelding, video: item.video, audio: item.audio, sort_order: item.sort_order};
            }
            return item;
        });
        this.updateFormInputItems();
    }

    getItem(uid) {
        //console.log('getItem', uid);
        let item = this.items.filter(i => i.uid === uid);
        //console.log('item', item);
        if (item.length) return item[0];
        return null;
    }

    addItem(type = 0, titel = '', lesmateriaal_text_id = 0, afbeelding = '', video = '', audio = '', sort_order = 0) {
        //console.log("addItem", titel, afbeelding, sort_order)
        //console.log("addItem", this.items);
        let uid = window.uid();
        let item = {uid: uid, type: type, titel: titel, placeholder: '', lesmateriaal_text_id: lesmateriaal_text_id, afbeelding: afbeelding, video: video, audio: audio, sort_order: sort_order};
        this.items.push(item);
        this.renderEl()
        $("#lesmateriaal_text").val("");
    }

    deleteItem(uid) {
        //console.log("deleteItem", uid);
        this.items = this.items.filter(i => i.uid !== uid);
        this.renderEl();
    }

    reorderItems(Uids) {
        //console.log("reorderItems", Uids);
        let item;
        let itemsNew = [];
        let teller = 0;
        Uids.forEach((uid, index) => {
            item = this.getItem(uid);
            itemsNew.push({uid: item.uid, type: item.type, titel: item.titel, placeholder: item.placeholder, lesmateriaal_text_id: item.lesmateriaal_text_id, afbeelding: item.afbeelding, video: item.video, audio: item.audio, sort_order: index});
        });
        this.items = itemsNew;
        this.updateFormInputItems();
        //console.log('reorderItems', this.items);
    }

    updateFormInputItems() {
        //console.log('updateFormInputItems');
        $('#gekoppeld-items').val(JSON.stringify(this.items));
    }

    selectFile(type) {
        //console.log("selectFile")
        //CKFinder.SetupFCKeditor( oFCKeditor, { BasePath : '/ckfinder/', StartupPath : 'Images:/path/to/directory/' } ) ;
        let startupPath = '';
        if (type == 2) startupPath = 'Afbeeldingen:/' + this.startupPath + '/';
        if (type == 3) startupPath = 'Video:/' + this.startupPath + '/';
        if (type == 4) startupPath = 'Audio:/' + this.startupPath + '/';

        console.log(startupPath);
        CKFinder.popup( {
            rememberLastFolder :  false,
            startupPath: startupPath,
            startupFolderExpanded:  true,
            chooseFiles: true,
            width: 800,
            height: 600,
            onInit: function( finder ) {
                finder.on( 'files:choose', function( evt ) {
                    let file = evt.data.files.first();
                    let url = file.getUrl();
                    let titel = '';
                    let lesmateriaal_text_id = 0;
                    let afbeelding = '';
                    let video = '';
                    let audio = '';
                    if (type == 2) afbeelding = url;
                    if (type == 3) video = url;
                    if (type == 4) audio = url;
                    window.gekoppeld.addItem(type, titel, lesmateriaal_text_id, afbeelding, video, audio);
                } );

                finder.on( 'file:choose:resizedImage', function( evt ) {
                    let url = evt.data.resizedUrl;
                    let titel = '';
                    let lesmateriaal_text_id = 0;
                    let afbeelding = '';
                    let video = '';
                    let audio = '';
                    if (type == 2) afbeelding = url;
                    if (type == 3) video = url;
                    if (type == 4) audio = url;
                    window.gekoppeld.addItem(type, titel, lesmateriaal_text_id, afbeelding, video, audio);
                } );
            }
        } );
    }
}
