<template>
    <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
            <i class="fas toast-icon" :class="iconClasses"></i>
            <strong class="mr-auto">{{ notification.title }}</strong>
            <small class="text-muted"></small>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" @click="remove()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="toast-body">
            {{ notification.message }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            timeout: null
        }
    },
    computed: {
        classType() {
            return {success: 'fa-check-circle', error: 'fa-times-circle', info: 'fa-info-circle'}[this.notification.type];
        },
        classColor() {
            return 'text-' + this.notification.type;
        },
        iconClasses() {
            return [this.classType, this.classColor];
        }
    },
    methods: {
        remove() {
            this.$emit('removeNotification', this.notification.id)
        },
    },
    mounted() {
        this.timeout = setTimeout(() => this.remove(), 5000)
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
    },
}
</script>

<style scoped>
.text-error {
    color: #dc3545;
}
.toast-icon {
    font-size: 20px;
    margin-right: 5px;
}
</style>
