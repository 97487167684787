window.wz = [];
window.wzEventListener;

window.toggleWoordzoekerToonVraag = function(uid) {
    var checkboxEl = $("#woordzoeker-"+uid+"-toon_vraag1");
    //console.log('toggleWoordzoekerToonVraag', uid, checkboxEl, checkboxEl.is(":checked"));
    if (checkboxEl.is(":checked")) {
        $("#v-woordzoeker-"+uid+"-woorden-container").addClass("toon-vraag");
        $("#woordzoeker-"+uid+"-woorden-container").addClass("toon-vraag");
    } else {
        $("#v-woordzoeker-"+uid+"-woorden-container").removeClass("toon-vraag");
        $("#woordzoeker-"+uid+"-woorden-container").removeClass("toon-vraag");
    }
}

$(document).on('change', 'select.woordzoeker-aantal_x', function () {
    let uid = $(this).closest(".properties").attr("data-uid");
    let aantalCelX = $(this).val();
    window.wz[uid].renderCelObjects(window.wz[uid].gCellen, aantalCelX, window.wz[uid].gAantalCelY);
    window.wz[uid].gAantalCelX = aantalCelX;
    window.wz[uid].renderCellen(this.gCellen);
});

$(document).on('change', 'select.woordzoeker-aantal_y', function () {
    let uid = $(this).closest(".properties").attr("data-uid");
    let aantalCelY = $(this).val();
    window.wz[uid].renderCelObjects(window.wz[uid].gCellen, window.wz[uid].gAantalCelX, aantalCelY);
    window.wz[uid].gAantalCelY = aantalCelY;
    window.wz[uid].renderCellen(this.gCellen);
});

$(document).click(function (event) {
    let clickedEl = $(event.target);
    if (clickedEl.hasClass('v-woordzoeker__cel__letter')) {
        $('input[type=text], textarea').blur();
        let uid = clickedEl.closest(".v-woordzoeker").attr('data-uid');
        Object.keys(window.wz).filter(k => k !== uid).forEach(key => {
            window.wz[key].deselectWoord();
            window.wz[key].gCurrentCelX = 0;
            window.wz[key].gCurrentCelY = 0;
        });
        $('input.woordzoeker-nummer').blur();
        let wz = window.wz[uid];
        let celX = clickedEl.closest(".v-woordzoeker__cel").data('x');
        let celY = clickedEl.closest(".v-woordzoeker__cel").data('y');
        if (celX == wz.gCurrentCelX && celY == wz.gCurrentCelY) {
            wz.gDirHorizontaal = wz.gDirHorizontaal ? 0 : 1;
        } else {
            wz.gCurrentCelX = celX;
            wz.gCurrentCelY = celY;
        }
        wz.selectWoord(wz.gCurrentCelX, wz.gCurrentCelY);
        if (window.wzEventListener) {
            document.removeEventListener('keydown', window.wzEventListener);
        }
        document.addEventListener('keydown', window.wzEventListener = window.wzKeydownCelSelected.bind(event, wz), false);
    } else {
        //console.log('removeEventListener', typeof window.wz);
        Object.keys(window.wz).forEach(key => {
            window.wz[key].deselectWoord();
            window.wz[key].gCurrentCelX = 0;
            window.wz[key].gCurrentCelY = 0;
        });
        document.removeEventListener('keydown', window.wzEventListener);
        window.wzEventListener = null;

    }
});

window.wzKeydownCelSelected = function(wz, keyboardEvent) {
    //console.log('wzKeydownCelSelected keyboardEvent', keyboardEvent);
    //console.log('wzKeydownCelSelected wz', wz);
    if (wz.gCurrentCelX > 0 && wz.gCurrentCelY > 0) {
        let key = keyboardEvent.key;
        if (key == 'ArrowRight') {
            wz.setNextLetterCel(wz.gCurrentCelX, wz.gCurrentCelY, wz.gDirHorizontaal);
        }
        if (key == 'ArrowLeft') {
            wz.setPrevLetterCel(wz.gCurrentCelX, wz.gCurrentCelY, wz.gDirHorizontaal);
        }
        if (key == 'Backspace') {
            wz.updateCelWithLetter(wz.gCurrentCelX, wz.gCurrentCelY, '');
            wz.renderCellen(wz.gCellen);
            wz.setPrevLetterCel(wz.gCurrentCelX, wz.gCurrentCelY, wz.gDirHorizontaal);
        }
        if (key.length == 1) {
            if (key.match(/[A-Za-z ]/)) {
                wz.updateCelWithLetter(wz.gCurrentCelX, wz.gCurrentCelY, key);
                wz.renderCellen(wz.gCellen);
                wz.setNextLetterCel(wz.gCurrentCelX, wz.gCurrentCelY, wz.gDirHorizontaal);
            }
        }
    }
}

window.Woordzoeker = class Woordzoeker {
    gUid = null;
    gCellenEl = null;
    gDirHorizontaal = 1;
    gAantalCelX = 0;
    gAantalCelY = 0;
    gCellen = [];
    gCurrentCelX = 0;
    gCurrentCelY = 0;
    gWoorden = [];
    gWoordenVraagEl = null;
    gWoordenPropertiesEl = null;

    constructor(gUid, gAantalCelX, gAantalCelY, gCellen, gWoorden) {
        this.gUid = gUid;
        this.gAantalCelX = gAantalCelX;
        this.gAantalCelY = gAantalCelY;
        this.gCellen = gCellen;
        this.gWoorden = gWoorden;
        this.gCellenEl = $("#v-woordzoeker-" + gUid + "-cellen");
        this.gWoordenVraagEl = $("#v-woordzoeker-" + gUid + "-woorden-container");
        this.gWoordenPropertiesEl = $("#woordzoeker-" + gUid + "-woorden-container");
    }

    renderCellen() {
        let cEl;
        let nummer;
        this.gCellenEl.html('');
        this.gCellenEl.css('grid-template-columns', 'repeat(' + this.gAantalCelX + ', 40px)');
        this.gCellenEl.css('grid-template-rows', 'repeat(' + this.gAantalCelY + ', 40px)');
        this.gCellen.forEach(cel => {
            cEl = '<div class="v-woordzoeker__cel" data-letter="' + cel.letter + '" data-x="' + cel.x + '" data-y="' + cel.y + '">' +
                '<div class="v-woordzoeker__cel__debug">' + cel.x + ',' + cel.y + '</div>' +
                '<div class="v-woordzoeker__cel__letter">' + cel.letter + '</div>' +
                '</div>';
            this.gCellenEl.append(cEl);
        });
        this.updateFormInputCellen();
    }

    updateFormInputCellen() {
        //console.log('updateFormInputCellen', this.gCellen);
        $('#woordzoeker-' + this.gUid + '-cellen').val(JSON.stringify(this.gCellen));
    }

    addWoord(uid = '') {
        //console.log('addWoord');
        if (!uid) uid = window.uid();
        let woord = {uid: uid, vraag: '', woord: ''};
        this.gWoordenPropertiesEl.append(this.woordElProperties(woord));
        this.gWoordenVraagEl.append(this.woordElVraag(woord));
        syncInput('woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag', 'v-woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag');
        syncInput('woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord', 'v-woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord');
        this.gWoorden.push(woord);
        this.updateFormInputWoorden();
    }

    changeWoordWoord(uid, woord) {
        //console.log('changeWoordWoord');
        this.gWoorden = this.gWoorden.map(w => {
            if (w.uid === uid) {
                return {uid: w.uid, woord: woord, vraag: w.vraag};
            }
            return w;
        });
        this.updateFormInputWoorden();
    }

    changeWoordVraag(uid, vraag) {
        //console.log('changeWoordVraag');
        this.gWoorden = this.gWoorden.map(w => {
            if (w.uid === uid) {
                return {uid: w.uid, woord: w.woord, vraag: vraag};
            }
            return w;
        });
        this.updateFormInputWoorden();
    }

    woordElProperties(woord) {
        return '<div class="woordzoeker-woord" id="woordzoeker-' + this.gUid + '-woord-' + woord.uid + '" data-uid="' + woord.uid + '">\n' +
            '<div class="d-flex justify-content-between mt-2">\n' +
            '<div class="form-group  flex-grow-1 mr-1">\n' +
            '<label for="woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord">Woord</label>\n' +
            '<input onchange="wz[\'' + this.gUid + '\'].changeWoordWoord(\'' + woord.uid + '\', this.value);" id="woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord" class="form-control" name="woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord" type="text" value="' + woord.woord + '">\n' +
            '<div class="validator-message"></div>\n' +
            '</div>\n' +
            '<div class="bk-icon bk-icon--small flex-shrink-0" onclick="wz[\'' + this.gUid + '\'].removeWoord(\'' + woord.uid + '\');"><i class="far fa-times"></i></div>\n' +
            '</div>\n' +
            '<div class="form-group mr-1">\n' +
            '<label for="woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag">Vraag</label>\n' +
            '<input onchange="wz[\'' + this.gUid + '\'].changeWoordVraag(\'' + woord.uid + '\', this.value);" id="woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag" class="form-control" name="woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag" type="text" value="' + woord.vraag + '">\n' +
            '<div class="validator-message"></div>\n' +
            '</div>\n' +
            '</div>';
    }

    woordElVraag(woord) {
        return '<div class="d-flex">\n' +
            '<div class="mr-3 mb-3 v-woordzoeker-woorden-vraag v-woordzoeker-woord" id="v-woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag">' + woord.vraag + '</div>\n' +
            '<div class="mb-3 v-woordzoeker-woorden-woord v-woordzoeker-woord" id="v-woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord">' + woord.woord + '</div>\n' +
            '</div>';
    }

    removeWoord(uid) {
        //console.log('removeWoord', uid);
        this.gWoorden = this.gWoorden.filter(w => w.uid !== uid);
        $("#woordzoeker-" + this.gUid + "-woord-" + uid).remove();
        this.updateFormInputWoorden();
    }

    renderWoorden() {
        //console.log("renderWoorden", this.gWoorden);
        let rowEl;
        let currentWoorden = this.gWoorden;
        let currentWoord;
        let currentText;
        // this.gWoorden = [];
        // this.gWoordenVraagEl.html('');
        this.gWoordenPropertiesEl.html('');
        this.gWoorden.forEach(woord => {
            this.gWoordenPropertiesEl.append(this.woordElProperties(woord));
            this.gWoordenVraagEl.append(this.woordElVraag(woord));
            syncInput('woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag', 'v-woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag');
            syncInput('woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord', 'v-woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord');
            syncInputFocus('woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag', 'v-woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-vraag', 'v-woordzoeker-woord');
            syncInputFocus('woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord', 'v-woordzoeker-' + this.gUid + '-woord-' + woord.uid + '-woord', 'v-woordzoeker-woord');
        });
        this.updateFormInputWoorden();
    }

    updateFormInputWoorden() {
        $('#woordzoeker-' + this.gUid + '-woorden').val(JSON.stringify(this.gWoorden));
    }

    renderCelObjects(currentCelObjects, aantalX, aantalY) {
        let celObjects = this.renderCelObjectsLetter(currentCelObjects, aantalX, aantalY);
        this.gCellen = celObjects;
    }

    renderCelObjectsLetter(currentCelObjects, aantalX, aantalY) {
        //console.log('renderCelObjectsLetter', currentCelObjects, aantalX, aantalY);
        let celObjects = [];
        let letter;
        let currentCel;
        for (let y = 1; y <= aantalY; y++) {
            for (let x = 1; x <= aantalX; x++) {
                currentCel = this.getCel(currentCelObjects, x, y);
                letter = currentCel ? currentCel.letter : '';
                celObjects.push({x: x, y: y, letter: letter});
            }
        }
        return celObjects;
    }

    getWoord(vragen, horizontaal, nummer) {
        let vraag = vragen.filter(v => v.horizontaal == horizontaal && v.nummer == nummer);
        if (vraag.length) return vraag[0];
        return null;
    }

    getCel(celObjects, x, y) {
        let celObject = celObjects.filter(c => c.x === x && c.y === y);
        if (celObject.length) return celObject[0];
        return null;
    }

    updateCelWithLetter(celX, celY, key) {
        let celObjects = this.gCellen.map(c => {
            if (c.x === celX && c.y === celY) {
                return {x: c.x, y: c.y, letter: key};
            }
            return c;
        });
        this.gCellen = celObjects;
    }

    isXStartOfWord(celObjects, cel) {
        //console.log('this.isXStartOfWord', celObjects, cel);
        if (cel.letter == "@") {
            return false;
        }
        let celXPrev = this.getCel(celObjects, cel.x - 1, cel.y);
        if (celXPrev && celXPrev.letter != "@") {
            return false;
        }
        let celXNext = this.getCel(celObjects, cel.x + 1, cel.y);
        if (celXNext && celXNext.letter != "@") {
            return true;
        }
        return false;
    }

    isYStartOfWord(celObjects, cel) {
        //console.log('this.isYStartOfWord', celObjects, cel);
        if (cel.letter == "@") {
            return false;
        }
        let celYPrev = this.getCel(celObjects, cel.x, cel.y - 1);
        if (celYPrev && celYPrev.letter != "@") {
            return false;
        }
        let celYNext = this.getCel(celObjects, cel.x, cel.y + 1);
        if (celYNext && celYNext.letter != "@") {
            return true;
        }
        return false;
    }

    selectWoord(celX, celY) {
        //console.log('selectWoord', celX, celY);
        this.deselectWoord();
        let celObject = this.getCel(this.gCellen, celX, celY);
        if (celObject.letter == "@") {
            return;
        }
        if (celX && celY) {
            if (this.gDirHorizontaal == 1) {
                let firstXOfWord = this.getFirstXOfWord(celX, celY);
                let lastCelXOfWord = this.getLastXOfWord(celX, celY);
                for (let x = firstXOfWord; x <= lastCelXOfWord; x++) {
                    this.selectCel(x, celY);
                }
            } else {
                let firstYOfWord = this.getFirstYOfWord(celX, celY);
                let lastCelYOfWord = this.getLastYOfWord(celX, celY);
                for (let y = firstYOfWord; y <= lastCelYOfWord; y++) {
                    this.selectCel(celX, y);
                }
            }
        }
    }

    deselectWoord() {
        //console.log('deselectWoord', this.gCellenEl);
        this.gCellenEl.find(".v-woordzoeker__cel").removeClass("v-woordzoeker__cel--selected").removeClass("v-woordzoeker__cel--current");
    }

    selectCel(celX, celY) {
        if (celX == this.gCurrentCelX && celY == this.gCurrentCelY) {
            this.gCellenEl.find(".v-woordzoeker__cel[data-x='" + celX + "'][data-y='" + celY + "']").addClass("v-woordzoeker__cel--current");
        } else {
            this.gCellenEl.find(".v-woordzoeker__cel[data-x='" + celX + "'][data-y='" + celY + "']").addClass("v-woordzoeker__cel--selected");
        }
    }

    getNextLetterCel(celX, celY, dirHorizontaal) {
        //console.log('getNextLetterCel', celX, celY, dirHorizontaal);
        if (dirHorizontaal) {
            let nextCelX = celX;
            let nextCelY = celY;
            let t = 0;
            while (true) {
                t = t + 1;
                if (t > 25) {
                    //console.log('while1 > 25');
                    break;
                }
                nextCelX++;
                if (nextCelX > this.gAantalCelX) {
                    nextCelX = 1;
                    nextCelY++;
                    if (nextCelY > this.gAantalCelY) {
                        nextCelY = 1;
                    }
                }
                let cel = this.getCel(this.gCellen, nextCelX, nextCelY);
                if (cel && cel.letter != "@") {
                    let celXPrev = this.getCel(this.gCellen, cel.x - 1, cel.y);
                    let celXNext = this.getCel(this.gCellen, cel.x + 1, cel.y);
                    if ((celXPrev && celXPrev.letter != "@") || (celXNext && celXNext.letter != "@")) {
                        break;
                    }
                }
            }
            return {celX: nextCelX, celY: nextCelY};
        } else {
            let nextCelX = celX;
            let nextCelY = celY;
            let t = 0;
            while (true) {
                t = t + 1;
                if (t > 25) {
                    //console.log('while1 > 25');
                    break;
                }
                nextCelY++;
                if (nextCelY > this.gAantalCelY) {
                    nextCelY = 1;
                    nextCelX++;
                    if (nextCelX > this.gAantalCelX) {
                        nextCelX = 1;
                    }
                }
                let cel = this.getCel(this.gCellen, nextCelX, nextCelY);
                if (cel && cel.letter != "@") {
                    let celYPrev = this.getCel(this.gCellen, cel.x, cel.y - 1);
                    let celYNext = this.getCel(this.gCellen, cel.x, cel.y + 1);
                    if ((celYPrev && celYPrev.letter != "@") || (celYNext && celYNext.letter != "@")) {
                        break;
                    }
                }
            }
            return {celX: nextCelX, celY: nextCelY};
        }
    }

    getPrevLetterCel(celX, celY, dirHorizontaal) {
        //console.log('getPrevLetterCel', celX, celY, dirHorizontaal);
        if (dirHorizontaal) {
            let nextCelX = celX;
            let nextCelY = celY;
            let t = 0;
            while (true) {
                t = t + 1;
                if (t > 25) {
                    //console.log('while1 > 25');
                    break;
                }
                nextCelX--;
                if (nextCelX < 1) {
                    nextCelX = this.gAantalCelX;
                    nextCelY--;
                    if (nextCelY < 1) {
                        nextCelY = this.gAantalCelY;
                    }
                }
                let cel = this.getCel(this.gCellen, nextCelX, nextCelY);
                if (cel && cel.letter != "@") {
                    let celXPrev = this.getCel(this.gCellen, cel.x - 1, cel.y);
                    let celXNext = this.getCel(this.gCellen, cel.x + 1, cel.y);
                    if ((celXPrev && celXPrev.letter != "@") || (celXNext && celXNext.letter != "@")) {
                        break;
                    }
                }
            }
            return {celX: nextCelX, celY: nextCelY};
        } else {
            let nextCelX = celX;
            let nextCelY = celY;
            let t = 0;
            while (true) {
                t = t + 1;
                if (t > 25) {
                    //console.log('while1 > 25');
                    break;
                }
                nextCelY--;
                if (nextCelY < 1) {
                    nextCelY = this.gAantalCelY;
                    nextCelX--;
                    if (nextCelX < 1) {
                        nextCelX = this.gAantalCelX;
                    }
                }
                let cel = this.getCel(this.gCellen, nextCelX, nextCelY);
                if (cel && cel.letter != "@") {
                    let celYPrev = this.getCel(this.gCellen, cel.x, cel.y - 1);
                    let celYNext = this.getCel(this.gCellen, cel.x, cel.y + 1);
                    if ((celYPrev && celYPrev.letter != "@") || (celYNext && celYNext.letter != "@")) {
                        break;
                    }
                }
            }
            return {celX: nextCelX, celY: nextCelY};
        }
    }

    getCEl(woordzoekerEl, celX, celY) {
        //console.log('this.getCel', woordzoekerEl, celX, celY);
        let cEl = woordzoekerEl.find(".v-woordzoeker__cel[data-x='" + celX + "'][data-y='" + celY + "']");
        if (cEl.length) {
            return cEl;
        }
        return null;
    }

    getCelObject(woordzoekerEl, celX, celY) {
        let cEl = woordzoekerEl.find(".v-woordzoeker__cel[data-x='" + celX + "'][data-y='" + celY + "']");
        if (!cEl.length) {
            return null;
        }
        let l = cEl.attr("data-letter");
        if (cEl.length) {
            return {celX: celX, celY: celY, letter: l};
        }
        return null;
    }

    setNextLetterCel(CelX, CelY, DirHorizontaal) {
        let nextLetterCel = this.getNextLetterCel(CelX, CelY, DirHorizontaal);
        this.gCurrentCelX = nextLetterCel.celX;
        this.gCurrentCelY = nextLetterCel.celY;
        this.selectWoord(this.gCurrentCelX, this.gCurrentCelY, DirHorizontaal);
    }

    setPrevLetterCel(CelX, CelY, DirHorizontaal) {
        let nextLetterCel = this.getPrevLetterCel(CelX, CelY, DirHorizontaal);
        this.gCurrentCelX = nextLetterCel.celX;
        this.gCurrentCelY = nextLetterCel.celY;
        this.selectWoord(this.gCurrentCelX, this.gCurrentCelY, DirHorizontaal);
    }

    getFirstXOfWord(celX, celY) {
        let firstCelX = celX;
        let tryCelX = firstCelX;
        let t = 0;
        while (true) {
            t = t + 1;
            if (t > 25) {
                //console.log('while5 > 25');
                break;
            }
            tryCelX--;
            let prevCelX = this.getCel(this.gCellen, tryCelX, celY);
            if (prevCelX && prevCelX.letter != "@") {
                firstCelX = tryCelX;
                continue;
            }
            break;
        }
        return firstCelX;
    }


    getLastXOfWord(celX, celY) {
        let lastCelX = celX;
        let tryCelX = lastCelX;
        let t = 0;
        while (true) {
            t = t + 1;
            if (t > 25) {
                //console.log('while6 > 25');
                break;
            }
            tryCelX++;
            let nextCelX = this.getCel(this.gCellen, tryCelX, celY);
            if (nextCelX && nextCelX.letter != "@") {
                lastCelX = tryCelX;
                continue;
            }
            break;
        }
        //console.log('getLastXOfWord return', lastCelX);
        return lastCelX;
    }

    getFirstYOfWord(celX, celY) {
        let firstCelY = celY;
        let tryCelY = firstCelY;
        let t = 1;
        while (true) {
            t = t + 1;
            if (t > 25) {
                //console.log('while7 > 25');
                break;
            }
            tryCelY--;
            let prevCelY = this.getCel(this.gCellen, celX, tryCelY);
            if (prevCelY && prevCelY.letter != "@") {
                firstCelY = tryCelY;
                continue;
            }
            break;
        }
        return firstCelY;
    }

    getLastYOfWord(celX, celY) {
        let lastCelY = celY;
        let tryCelY = lastCelY;
        let t = 0;
        while (true) {
            t = t + 1;
            if (t > 25) {
                //console.log('while8 > 25');
                break;
            }
            tryCelY++;
            let nextCelY = this.getCel(this.gCellen, celX, tryCelY);
            if (nextCelY && nextCelY.letter != "@") {
                lastCelY = tryCelY;
                continue;
            }
            break;
        }
        return lastCelY;
    }
}
