window.capitalizeFirstLetter = function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

window.dec2hex = function(dec) {
    return dec.toString(16).padStart(2, "0")
}

window.uid = function(len = 6) {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
}

window.syncInputFocus = function(elInputId, elTargetId, removeClass) {
    document.getElementById(elInputId).addEventListener('focus', function() {
        $("." + removeClass).removeClass("focus");
        $("#" + elTargetId).addClass("focus");
    });
    document.getElementById(elInputId).addEventListener('blur', function() {
        $("." + removeClass).removeClass("focus");
    });
}

window.syncInputId = function (elId) {
    el = $("#" + elId);
    $("#" + el.data('sync-input-id')).val($("#" + elId).html());
}

window.syncInput = function (elId, syncElId = null) {
   //console.log('syncInput elId: ' + elId + ' syncElId: ' + syncElId);
    var el = $("#" + elId);
    //console.log('el', el);
    if (syncElId) {
        var syncEl = $("#" + syncElId);
    } else {
        var syncElId = $("#" + el.data('sync_id'));
        var syncEl = $("#" + syncElId);
    }

    syncEl.html(el.val());
    document.getElementById(elId).addEventListener("input", function () {
        $("#"+syncElId).html(el.val());
        //console.log('syncInput ' + el.val());
    }, false);
}


window.syncCkeditor = function (elId, syncElId = null) {
    //console.log('syncInput elId: ' + elId + ' syncElId: ' + syncElId);
    var el = $("#" + elId);
    //console.log('el', el);
    if (syncElId) {
        var syncEl = $("#" + syncElId);
    } else {
        var syncElId = $("#" + el.data('sync_id'));
        var syncEl = $("#" + syncElId);
    }
    syncEl.html(el.val());

    document.getElementById(elId).addEventListener("input", function () {
        syncEl.html(el.val());
        //console.log('syncInput ' + el.val());
    }, false);
}

window.pasteHtmlAtCursor = function (html) {
    var sel, range;
    if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            // Range.createContextualFragment() would be useful here but is
            // non-standard and not supported in all browsers (IE9, for one)
            var el = document.createElement("div");
            el.innerHTML = html;
            var frag = document.createDocumentFragment(), node, lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);
            // Preserve the selection
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    } else if (document.selection && document.selection.type != "Control") {
        // IE < 9
        document.selection.createRange().pasteHTML(html);
    }
}

window.openCKFinder = function(startupPath = '') {
    CKFinder.modal({
        rememberLastFolder :  false,
        startupPath: startupPath,
        startupFolderExpanded:  true,
        chooseFiles: false
    });
}



